import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import ImageText from '../../Components/ImageText/ImageText'
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import HFTabs from '../../Components/HfTabs/HfTabs'
import { Col, Container, Row } from 'react-bootstrap'
import ImageCard from '../../Components/ImageCard/ImageCard'

import imgStage from '../../assets/Products/Filtration/Stage.jpg'
import HighQualityFilters from '../../assets/Products/Filtration/HighQualityFilters.jpg'
import SuctionFilters from '../../assets/Products/Filtration/SuctionFilters.jpg'
import PressureFilters from '../../assets/Products/Filtration/PressureFilters.jpg'
import ReturnFlowFilters from '../../assets/Products/Filtration/ReturnFlowFilters.jpg'
import SpinOnFilters from '../../assets/Products/Filtration/SpinOnFilters.jpg'
import ItAllComes from '../../assets/Products/Filtration/ItAllComes.jpg'
import FilterSystem from '../../assets/Products/Filtration/FilterSystem.jpg'
import DevicesAndInstruments from '../../assets/Products/Filtration/DevicesAndInstruments.jpg'
import HydraulicFluid from '../../assets/Products/Filtration/HydraulicFluid.jpg'
import OilCare from '../../assets/Products/Filtration/OilCare.jpg'
import ProtectHydraulicSystem from '../../assets/Products/Filtration/ProtectHydraulicSystem.jpg'
import OilAnalysis from '../../assets/Products/Filtration/OilAnalysis.jpg'
import OilCleaning from '../../assets/Products/Filtration/OilCleaning.jpg'
import ContinuousMonitorOil from '../../assets/Products/Filtration/ContinuousMonitorOil.jpg'

const Filtration = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const Filtration1 = {
        image: HighQualityFilters,
        title: 'High-quality filters and filter elements',
        description: 'HANSA‑FLEX hydraulic filters reduce solid particle contamination in fluid circuits and protect sensitive components. With filters and filter systems from HANSA‑FLEX, you can be sure that your oil meets the required level of cleanliness and retains its optimum fluid properties.',
        listItems: ["Filters, filter elements and filter housings", "Adsorber, pressure, return, suction and spin-on filters", "Line filters, replaceable filters and suitable accessories"],
    }

    const Filtration2 = {
        image: ItAllComes,
        description: 'As fluid experts, we offer you a comprehensive range of filter elements such as suction, pressure, return and spin-on filters for use throughout your fluid systems. We also support you in designing and dimensioning your filters. We take into account the following important factors:',
        listItems: ["Volume flow and viscosity of the fluid", "Desired oil cleanliness and amount of contamination", "Required filter service life, size and type of system", "Filter medium"],
    }

    const Filtration3 = {
        image: FilterSystem,
        title: 'Filter systems for cleaning fluids',
        description: 'Quality and cleanliness requirements for oil in hydraulic systems are continually rising. With HANSA‑FLEX filter systems, you benefit from an effective care regime for your hydraulic oil, which also contributes to fault-free operation of your hydraulic systems.',
        listItems: ["Partial flow filter systems for purchase or hire", "Oil service equipment and accessories such as equipment carriers for transport", "Mobile filling pumps for cleaning oil while filling tanks", "Replaceable filter elements"],
    }

    const Filtration4 = {
        image: DevicesAndInstruments,
        title: 'Devices and instruments for oil analysis and sampling',
        description: 'Using sensors, rapid testing devices and oil analysis sets, you can implement a comprehensive fluid condition monitoring regime and perform other tests.',
        listItems: ["Oil analysis devices with suitable accessories", "Particle sensors, particle counters, vacuum filtration devices", "Oil analysis sets for mineral, bio- and gear oils", "Fluid sampling sets and sample bottles in glass or plastic", "Vacuum hand pump"],
    }

    const Filtration5 = {
        image: HydraulicFluid,
        title: 'Hydraulic fluids and oil binding agents',
        description: "We can supply you with hydraulic and engine oils for automotive, industrial, construction uses, agriculture and forestry machines. Also available are highly absorbent oil binding agents to clean up surfaces where oil has dripped or escaped.",
        listItems: ["Transmission and hydraulic oil", "Mineral oil-based engine oil", "Hydraulic oil based on plant or mineral oils, and synthetic oil", "Oil binding agents (granules, mats, natural fibres)", "Oil stain removers"]
    }

    const Filtration6 = {
        image: OilCare,
        title: 'Oil care made easy with mobile filling pumps from HANSA‑FLEX',
        description: "These CE-certificated filling pumps with two-stage filtration clean fresh or old oil while filling or emptying oil tanks. The high-performance motor allows a continuous duty cycle of up to 3 hours.",
        listItems: ["Volume flow 15 l/min, 230V AC motor with brand-name quality", "Integrated oil containment tray and visual clogging indicator", "Permissible operating temperature of -20 °C to +110 °C"],
        buttonName: "LEARN MORE"
    }

    const Filtration7 = {
        image: ProtectHydraulicSystem,
        title: 'Protect hydraulic systems from condensation water with adsorber filters',
        description: "Adsorber filters are bolted onto the system and provide comprehensive protection in environments with high relative humidities, large temperature fluctuations or where biologically degradable oils are used.",
        listItems: ["Suitable for interior and exterior use, for tanks, drums and IBC containers", "The silica gel changes colour to show the water saturation level of the filter", "An integrated 3 µm filter element also contributes to clean oil"],
        buttonName: "TO THE ADSORBER FILTERS"
    }


    //HfTab data
    const imageTextData = [
        {
            title: "Suction filter used as a protective filter",
            listItems: ["Installation: submerged in the tank", "Permissible operating temperature: -25 °C to 110 °C", "Note: not suitable for depth filtration"],
            buttonName: "SHOP NOW",
            image: SuctionFilters
        },
        {
            title: "Pressure filter used as a working or protective filter",
            description: "Pressure filters are used with high system pressures. They protect the system and sensitive components such as proportional valves and servo valves from contaminants.",
            listItems: ["Compact design", "Flow-optimised, durable filter housing", "For low, medium and high pressures (up to 60, 320 and 450 bar)"],
            buttonName: "SHOP NOW",
            image: PressureFilters
        },
        {
            title: "Heat exchangers for efficient temperature regulati",
            description: "Return flow filters are installed in the return line ahead of or on the hydraulic tank. They filter the oil before it returns to the tank and capture the dirt particles created in the system. This ensures that no contaminants from the system find their way into the tank and that they do not circulate within the system. Bypass valves are frequently integrated into hydraulic systems to protect them and the rest of the filters from excessive blockage pressures in the return flow line.",
            buttonName: "SHOP NOW",
            image: ReturnFlowFilters
        },
        {
            title: "Spin-on filter used as a suction filter or return",
            description: "Spin-on filters consist of a diecast aluminium filter head and a replaceable screw-on metal filter cartridge.",
            listItems: ["Easy replacement of the filter element", "Compact design and simple construction", "Excellent cost-benefit figures"],
            buttonName: "SHOP NOW",
            image: SpinOnFilters
        },
    ];

    const tabTitles = [
        { title: "Suction filters", eventKey: "Suction filters" },
        { title: "Pressure filters", eventKey: "Pressure filters" },
        { title: "Return flow filters", eventKey: "Return flow filters" },
        { title: "Spin-on filters", eventKey: "Spin-on filters" },
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"FILTER TECHNOLOGY FROM HANSA‑FLEX"}
                    paragraph={"Effective filtration is fundamental to the full functionality and long service life of highly-sensitive hydraulic systems. With HANSA‑FLEX filter technology you can be sure of having clean oil and trouble-free operation of your machines and systems. In the HANSA‑FLEX online shop, you can find filter elements and systems as well as other associated products such as oil analysis sets and particle counters for a highly effective Fluid management regime."}
                />
            </div>

            <div>
                <ImageText
                    data={Filtration1} />
            </div>

            <div>
                <SectionHeader
                    title={"THE PERFECT FILTER SOLUTION FOR ALL HYDRAULICS APPLICATIONS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"IT ALL COMES DOWN TO CORRECT DESIGN AND DIMENSIONING"}
                    subtitle={"Our experts know the importance of correct design and dimensioning for achieving the best filtration performance."}
                />
                <ImageText
                    data={Filtration2} />
            </div>

            <div>
                <SectionHeader
                    title={"HIGH-PERFORMANCE FILTRATION"}
                    subtitle={"In addition to high-performance filters, the HANSA‑FLEX product portfolio lists more products for effective fluid care, such as filter systems, oil analysis sets, particle counters and oil binding agents."}
                />
                <ImageText
                    data={Filtration3} />
                <ImageText
                    data={Filtration4} left={false} />
                <ImageText
                    data={Filtration5} />
            </div>

            <div>
                <SectionHeader
                    title={"PREVENT 70 % OF ALL SYSTEM FAILURES WITH FLUID MANAGEMENT"}
                    subtitle={"Leave oil care in the responsible hands of our experienced HANSA‑FLEX fluid specialists. We support you in all your fluid management activities such as fluid cleaning, or by performing effective oil analyses to troubleshoot problems in hydraulic systems."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"HANSA‑FLEX SOLUTIONS FOR SUSTAINABLE OIL FILTRATION"}
                    subtitle={"Our know-how on every aspect of fluid technology is reflected in our record of product development. Here are some details of two of these products for filtering fluids."}
                />
                <ImageText
                    data={Filtration6} />
                <ImageText
                    data={Filtration7} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"360° FLUID MANAGEMENT FROM THE HANSA‑FLEX FLUID SPECIALISTS"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={OilAnalysis}
                                title="Oil analysis gives the hydraulic system a thorough check-up"
                                desc={"Our Fluid Service performs oil analyses directly on site or later in the laboratory. You receive a summarised report with suggestions for optimisation based on the"} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={OilCleaning}
                                title="Oil cleaning extends service life and prevents wear"
                                desc={"The fluid experts from HANSA‑FLEX perform professional oil cleaning to achieve the desired cleanliness level and install the necessary filter technology."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={ContinuousMonitorOil}
                                title="Continuously monitor oil condition with sensors"
                                desc={"Keep an eye on your hydraulic fluids with oil condition sensors. Abnormalities are detected quickly and problems rectified in good time."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}


export default Filtration