import React from 'react';
import './TextCaption.css';
import { Link } from 'react-router-dom';

const TextCaption = ({ title, subtitle, paragraph, bulletPoints, buttonLink, buttonName, numbered, version = 0 }) => {

  var paddingTop = "4rem";
  var marginleft = "15%";

  if (version === 2) {
    paddingTop = "1rem";
    marginleft = "10%";
  }

  
  // Function to check if the link is internal
  const isInternalLink = link => link.startsWith('/');

  // Function to check if the link is a PDF
  const isPDFLink = link => link.endsWith('.pdf');

  return (
    <div className="text-caption-header" style={{ marginleft: marginleft }}>
      {title && <h1 className="text-caption-title" style={{ color: '#28588F', paddingTop: paddingTop }}>{title}</h1>}
      {subtitle && <p className="text-caption-subtitle">{subtitle}</p>}
      {paragraph && <p className="text-caption-paragraph">{paragraph}</p>}
      {bulletPoints && (
        <ul className={`bullet-point-list text-caption-bullets ${numbered ? 'numbered' : 'bullet'}`}>
          {bulletPoints.map((point, index) => (
            <li key={index}>{/* Using the Circle icon from React Icons */}
              {point}
            </li>
          ))}
        </ul>
      )}
      {buttonName && buttonLink && (
        isInternalLink(buttonLink) ? (
          // Internal link, render in the same window
          <Link to={buttonLink} className="ImageText-button">
            {buttonName}
          </Link>
        ) : (
          // External link, check if it's a PDF
          isPDFLink(buttonLink) ? (
            <a
              href={buttonLink}
              className="ImageText-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              {buttonName}
            </a>
          ) : (
            <a
              href={buttonLink}
              className="ImageText-button"
            >
              {buttonName}
            </a>
          )
        )
      )}
    </div>
  );
};

export default TextCaption;
