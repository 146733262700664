import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import Hydraulicunit_Centerspread from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgHydraulicunit_Imagetext_01 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagetext_01.jpg'
import imgHydraulicunit_Imagetext_02 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagetext_02.jpg'
import imgHydraulicunit_Imagetext_03 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagetext_03.jpg'
import imgHydraulicunit_Imagetext_04 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagetext_04.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png';
import { Container, Row, Col } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgHydraulicunit_Imagecard_01 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagecard_01.jpg'
import imgHydraulicunit_Imagecard_02 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagecard_02.jpg'
import imgHydraulicunit_Imagecard_03 from '../../../assets/Services/Engineering/Hydraulic unit manufacture/Hydraulicunit_Imagecard_03.jpg'

const Hydraulicunit = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;



  const Hydraulicunit_Imagetext_01 = {
    image: imgHydraulicunit_Imagetext_01,
    title: 'High-quality power units manufactured one-off or in series production',
    description: ['HANSA‑FLEX coordinates a complex development process at the end of which you receive the product of proven quality. This process involves five steps:.'],
    listitems: ['Analysis and advice', 'Drawing and simulation', 'Tests on the prototype', 'Checks of initial sampling'],
  }

  const Hydraulicunit_Imagetext_02 = {
    image: imgHydraulicunit_Imagetext_02,
    title: 'Specific advice and project planning from experts',
    description: ['The inspection tools we use include coating thickness meters, which, for example, check the correct application of a coating or paint. We use high-tech 3D laser scanners to check dimensions. Our automatic laser scanners compare the component with the relevant drawing automatically so that checks can be made quickly, reliably and precisely, even with large production volumes.'],

  }

  const Hydraulicunit_Imagetext_03 = {
    image: imgHydraulicunit_Imagetext_03,
    title: 'Installation and other services from experts',
    description: ['Our installation technicians professionally install and check the functions of the completed hydraulic power unit and, if required, assist you in bringing it into first use on site. Our service technicians are available to perform regular servicing at the specified intervals. They are also there in the event of malfunctions or system breakdowns and can repair power units, component groups or complete systems. The close-meshed network of HANSA‑FLEX branches and the excellent availability of parts from our two modern central warehouses mean we can be on site quickly to ensure any standstills are short.'],

  }

  const Hydraulicunit_Imagetext_04 = {
    image: imgHydraulicunit_Imagetext_04,
    title: '',
    description: ['A hydraulic power unit provides the drive for hydraulic consumers such as cylinders, motors and the like. It consists of components such as a hydraulic oil reservoir, an electric or pneumatic motor or an internal combustion engine, hydraulic pumps and valves. The pumps are driven by the motor to maintain the flow of oil. This flow of oil is directed to consumers (actuators) by control components (directional control valves) and is responsible for their movements. The higher the pressure, the higher the force produced by the consumer. The higher the rate of flow, the faster the consumer moves.'],

  }



  const featuresData = [
    {
      title: "All from the same source",
      description: "From advice to servicing, with HANSA‑FLEX everything comes from the same source..",
      image: imgMark
    },
    {
      title: "Individually made to the customer’s wishes",
      description: "Advice, manufacturing to specific requirements and implementation to fulfil the customer’s special requests.",
      image: imgMark
    },
    {
      title: "Manufacturer-neutral choice",
      description: "Access to a wide range and procurement of the optimum components for your solution.",
      image: imgMark
    },
    {
      title: "One-off and series production",
      description: "Manufacture of power units in all volumes from one-off items to large volume series production.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];



  return (
    <div>


      <CenterStage
        imageSrc={Hydraulicunit_Centerspread}
        title={"THE OPTIMUM HYDRAULIC POWER UNIT FOR YOUR SYSTEM"}
        paragraph={"Hydraulic power units are the driving force at the heart of many hydraulic systems. Their components include a motor to provide the energy, a fluid reservoir, pumps and valves. HANSA‑FLEX develops individual solutions perfectly made-to-measure for the requirements of our customers, manufacturing units as one-off items or in series production. We advise you extensively on unit sizing and component selection and provide servicing support."}
      />

      <div >
        <SectionHeader
          title={"MANUFACTURE OF HYDRAULIC POWER UNITS FOR ALL USAGE SCENARIOS"}
          subtitle={"With hydraulic power units from HANSA‑FLEX, everything from the first outline to the finished product comes from a single source."}
        />


      </div>

      <div >
        <br />
        <ImageText data={Hydraulicunit_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Hydraulicunit_Imagetext_02} />
      </div>
      <div >
        <br />
        <ImageText data={Hydraulicunit_Imagetext_03} left={false} />
      </div>





      <div >
        <SectionHeader
          title={"WORKING PRINCIPLES OF HYDRAULIC POWER UNITS"}
          subtitle={"Hydraulic power units are a combination of several components designed to keep hydraulic oil on the move."}
        />


      </div>





      <div >
        <br />
        <ImageText data={Hydraulicunit_Imagetext_04} />
      </div>

      <div >
        <SectionHeader
          title={"ADVANTAGES OF HANSA‑FLEX HYDRAULIC POWER UNITS"}
          subtitle={"Benefit from our extensive product portfolio of hydraulic pumps, high availability and first-class advice from experience experts."}
        />


      </div>

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>


      <div >
        <SectionHeader
          title={"HYDRAULIC POWER UNITS MADE-TO-MEASURE"}
          subtitle={"HANSA‑FLEX does not only supply hydraulic power units. The comprehensive technical knowledge of our specialists flows into the development of each new solution. The advice of experts makes the difference in these situations."}
        />


      </div>




      <div >
        <SectionHeader
          title={"OVERHAULING DEFECTIVE HYDRAULIC COMPONENTS"}
          subtitle={"Our experienced experts perform repairs on damaged hydraulic cylinders of all sizes, manufacturers and types, completing them in the quickest possible time. The HANSA‑FLEX cylinder repair centre has a variety of modern machinery and equipment available to professionally overhaul cylinders."}
        />


      </div>


      <div >
        <SectionHeader
          title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
          subtitle={""}
        />


      </div>

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHydraulicunit_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="HANSA‑FLEX hydraulic hoses" desc="HANSA‑FLEX hydraulic hoses, some of which are developed in-house, fulfil the highest quality standards in the industry." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgHydraulicunit_Imagecard_02} learnMoreLink="/metal-hoses" title="High-quality pipe fittings" desc="Pipe fittings were one of the first in-house developments at HANSA‑FLEX. Even today, these hydraulic components are a safe way of connecting pipelines." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgHydraulicunit_Imagecard_03} learnMoreLink="/hydraulic-hoses" title="Reliable sealing technology" desc="A hydraulic system is only as good as its seals. Just one defective seal can lead to long downtimes. HANSA‑FLEX helps to prevent this." />
          </Col>


        </Row>
      </Container>














    </div>
  )
}

export default Hydraulicunit