import React from "react";
import Slider from "react-slick";
import FeaturesCard from '../FeaturesCard/FeaturesCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomNextArrow from "./CustomNextArrow";
import CustomPrevArrow from "./CustomPrevArrow";
import "./FeaturesCardCarousel.css";
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from "react-responsive";

function FeatureCardCarousel({ featuresData }) {

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size

  if (isLaptop) {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      prevArrow: <CustomPrevArrow className="slick-prev" />,
      nextArrow: <CustomNextArrow className="slick-next" />
    };

    return (
      <div className="slider-container">
        <Slider {...settings}>
          {featuresData.map((feature, index) => (
            <div className="slider-item" key={index}>
              <FeaturesCard
                title={feature.title}
                description={feature.description}
                image={feature.image}
              />
            </div>
          ))}
        </Slider>
      </div>
    );
  } else {
    return (
      <Container>
        <Row>
          {featuresData.map((feature, index) => (
            <Col key={index} sm={12} md={6} className='center-all-items'>
              <FeaturesCard
                title={feature.title}
                description={feature.description}
                image={feature.image}
              />
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

export default FeatureCardCarousel;
