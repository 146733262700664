import React from 'react';
import './ContactUsCard.css'; // Import CSS file

const ContactUsCard = ({ units }) => {
    return (
        <div className="cards-container">
            {units.map((unit, index) => (
                <div
                    key={index}
                    className="contact-card"
                    style={{
                        backgroundImage: `url(${unit.image})`,
                    }}
                >
                    <div className="content">
                        <h2 className="unit-name">{unit.name}</h2>
                        {unit.description && <h6 className="description">{unit.description}</h6>}

                        <div className="info">
                            <div>
                                <p className="label">ADDRESS:</p>
                                {/* <p className="text">{unit.location}</p> */}
                                <p>
                                    <a
                                        className="text"
                                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(unit.location)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {unit.location}
                                    </a>
                                </p>
                            </div>
                            <div>
                                <p className="label">TELEPHONE:</p>
                                {/* <p className="text">{unit.telephone}</p> */}
                                <p>
                                    <a
                                        className="text"
                                        href={`tel:${unit.telephone.replace(/\s+/g, '')}`}
                                    >
                                        {unit.telephone}
                                    </a>
                                </p>
                            </div>
                            {unit.fax && (
                                <div>
                                    <p className="label">FAX:</p>
                                    {/* <p className="text">{unit.fax}</p> */}
                                    <p>
                                        <a
                                            className="text"
                                            href={`tel:${unit.fax.replace(/\s+/g, '')}`}
                                        >
                                            {unit.fax}
                                        </a>
                                    </p>
                                </div>
                            )}
                            {unit.mail && (<div>
                                <p className="label">E-MAIL:</p>
                                {/* <p className="text">{unit.mail}</p> */}
                                <a
                                    className="text"
                                    href={`mailto:${unit.mail}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {unit.mail}
                                </a>
                            </div>)}
                        </div>
                        <button
                            className="copy-button"
                            onClick={() => {
                                navigator.clipboard.writeText(unit.mail);
                                alert('Email copied to clipboard!');
                            }}
                        >
                            Copy E-mail Address
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ContactUsCard;
