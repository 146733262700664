import React, { useEffect } from 'react'
import imgNewTraining from '../../assets/imageTraining.jpg'
import CenterStage from '../../Components/CenterStage/CenterStage'
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import img1new from '../../assets/1new.png'
import img2new from '../../assets/2new.png'
import img3new from '../../assets/3new.png'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'
import TwoColumnPoints from '../../Components/TwoColumnPoints/TwoColumnPoints'
import TextCaption from '../../Components/TextCaption/TextCaption'
import imageTraining2 from '../../assets/imageTraining2.jpg'




const Training = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  const featuresData = [
    {
      title: "Comprehensive Curriculum",
      description: "Dive into hydraulic systems, covering diagrams, Pascal's Law, and fluid mechanics.",
      image: img1new
    },
    {
      title: "Hands-On Practice",
      description: "Engage in practical exercises with pumps, valves, and flow control techniques to reinforce your learning.",
      image: img2new
    },
    {
      title: "Certification Achievement",
      description: "Complete the program and receive a certificate to validate your new skills and knowledge.",
      image: img3new
    }
  ]

  return (
    <div>
      <div>
        <CenterStage
          imageSrc={imageTraining2}
          title="Training at HANSA-FLEX: The Perfect Start for Your Career"
          paragraph="Join us for an intensive 3 days training program designed to enhance your skills. Complete the training and earn your certificate to showcase your achievement! " />
      </div>

      <div>
        <SectionHeader
          title="3 Days Training +Practice + Certificate"
          subtitle="Discover the key components of our comprehensive training program."
        />
        <FeatureCardCarousel
          featuresData={featuresData}
        />
      </div>

      <div>
        <TwoColumnPoints title={"Training Content Highlights"}
          points={[
            "Structure and construction of a hydraulic diagram",
            "Basic concepts including Pascal's Law",
            "Fluids to be applied",
            "Fixed and adjustable pumps",
            "Pilot valves, safety valves, throttling and flow control valves",
            "Filtration technology",
            "Troubleshooting and interpretation"
          ]} />
      </div>

      <TextCaption
                paragraph="Prospects after the Training - Your Start into the Future"
                bulletPoints={[
                    "Go directly into employment! - We take over more than 90% of our trained apprentices into employment.,",
                    "Get in wherever you want! - Whether in the head office, the branches or the production areas - at HANSA-FLEX there are a wide range of opportunities for your first job at home and abroad.",
                    "Work abroad for a while! - With the HANSA-FLEX Year of Wandering we make it possible for you to work in one of our foreign branches.",
                    "Become a hydraulic specialist! - If you have a strong interest in technology and craftsmanship, we can offer you further training to become a hydraulics specialist with a recognised HWK qualification.",
                    "Educate yourself further! - We support your development even after your training. HANSA-FLEX is particularly keen to promote specialist further and advanced training.",
                ]}
            />
      {/* <div>
        <TextCaption
          title="Prospects after the Training - Your Start into the Future"
          paragraph="After completing our training program, a world of opportunities opens up for you at HANSA-FLEX. We're committed to nurturing talent and providing pathways for growth and development."
          bulletPoints={[
            "Go directly into employment! - We take over more than 90% of our trained apprentices into employment.",
            "Get in wherever you want! - Whether in the head office, the branches or the production areas - at HANSA-FLEX there are a wide range of opportunities for your first job at home and abroad.",
            "Work abroad for a while! - With the HANSA-FLEX Year of Wandering we make it possible for you to work in one of our foreign branches.",
            "Become a hydraulic specialist! - If you have a strong interest in technology and craftsmanship, we can offer you further training to become a hydraulics specialist with a recognised HWK qualification.",
            "Educate yourself further! - We support your development even after your training. HANSA-FLEX is particularly keen to promote specialist further and advanced training."
          ]}
        />
      </div> */}


      <br /><br /><br />

    </div>

  )
}

export default Training