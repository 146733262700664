
import React from 'react';
import './CenterStage.css'
import SectionHeader from '../SectionHeader/SectionHeader';


const CenterStage = ({ imageSrc, title, paragraph }) => {

  const imageStyle = {
    width: '100%', // Set width to fill container
    height: '500px', // Maintain aspect ratio
    objectFit: 'cover', // Crop the image to cover the entire container
    padding: '0px'
  };

  return (
    <div className="stage-container">
      <div className="stage-image-container">
        <img
          className='img-center-stage'
          src={imageSrc}
          alt="First slide"
          style={imageStyle}
        />
      </div>

      <div className=" stage-text-container">
        <SectionHeader title={title} subtitle={paragraph} />
      </div>
    </div>
  );
};

export default CenterStage;
