import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import img_Product_Pipeend_Centerspread from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_centerstage.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import img_Products_Pipeend_imagetext1 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext1.jpg'
import img_Products_Pipeend_imagetext2 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext2.jpg'
import img_Products_Pipeend_imagetext3 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext3.jpg'
import img_Products_Pipeend_imagetext4 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext4.jpg'
import img_Products_Pipeend_imagetext5 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext5.jpg'
import img_Products_Pipeend_imagetext6 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext6.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { Container, Row, Col } from 'react-bootstrap';
import imgProductPipeendimagecard1 from '../../../assets/Products/Pipelines/Pipe-end processing/Pipeend_Imagecard1.jpg'
import imgProductPipeendimagecard2 from '../../../assets/Products/Pipelines/Pipe-end processing/Pipeend_Imagecard2.jpg'
import imgProductPipeendimagecard3 from '../../../assets/Products/Pipelines/Pipe-end processing/Pipeend_Imagecard3.jpg'
import AllConnections from '../../../assets/Products/Pipelines/Pipe-end processing/AllConnections.jpg'

import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText';
import imgMark from '../../../assets/mark.png'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'

const PipeendProcessing = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;



  const ProductPipebendimagetext1 = {
    image: img_Products_Pipeend_imagetext1,
    title: 'The pipe bending centre and branches work hand-in-hand',
    description: ['The experts in the HANSA‑FLEX pipe bending centre have the necessary skills and knowledge of pipe bending and fluid technology to master the most complex challenges. They work closely with the specialists from the HANSA‑FLEX branches. You can expect the best possible advice and an optimum path from conception to manufacture. On request, HANSA‑FLEX is happy to manufacture ready-to-install pipe sets and deliver them Just-In-Time directly to the customer’s installation site, so that the entire process after pipe bending runs smoothly..'],
  }

  const ProductPipebendimagetext2 = {
    image: img_Products_Pipeend_imagetext2,
    title: 'Checking the shape of pipe bends',
    description: ['The bending experts at HANSA‑FLEX determine all the data required for the bent pipe from the computer simulation. If the simulation shows that the degree of ovalisation in the bend area exceeds the 10 % limit applied in hydraulics, then an internal mandrel is used during bending to prevent the pipe cross section from narrowing.Reducing the ovalisation reduces turbulence in the pipe system and increases efficiency.'],
  }


  const ProductPipebendimagetext3 = {
    image: img_Products_Pipeend_imagetext3,
    title: 'Well-equipped production floor',
    description: ['To meet the diverse requirements for pipe geometry needs many more than one type of machine. The extensive range of machines in the pipe bending centre allows HANSA‑FLEX to perform a wide variety of bending tasks and produce the highest quality results.HANSA‑FLEX continually acquires new machines to ensure not only the highest precision but also maximum flexibility on the production floor. Around 20 further CNC machines augment a fully automated bending cell.'],
  }


  const ProductPipebendimagetext4 = {
    image: img_Products_Pipeend_imagetext4,
    title: 'Special fittings instead of towers of screw fittings',
    description: ['HANSA‑FLEX manufactures special fittings for a diverse range of industries and applications. Multiply bent fittings, any form of adapter with a variety of threads, including complex collectors and manifolds. The design is always decided in consultation with the customer, recorded on a drawing and then manufactured.Special fittings avoid having to connect several adapters and connection pieces one after another. This in turn considerably reduces material consumption, weight and the risk of leaks.'],
  }

  const ProductPipebendimagetext5 = {
    image: img_Products_Pipeend_imagetext5,
    title: 'Special procedures for soldering and welding',
    description: ['HANSA‑FLEX offers all available soldering processes for forming safe connections. Continuous soldering furnace for hard and high-temperature soldering under protective gas, for example soldering a length of 16 m at a rate of approximately 100 kg/h at a maximum temperature of 1,130 °C.HANSA‑FLEX is certified to carry out welding processes in accordance with GSI, DIN EN 15085 2, DIN 2303 Q2 BK1 and DIN EN ISO 3834. HANSA‑FLEX also has an orbital welding machine. This technique produces a consistently high and reproducible weld quality irrespective of the throughput..'],
  }


  const ProductPipebendimagetext6 = {
    image: img_Products_Pipeend_imagetext6,
    title: 'Face-to-face advice on site',
    description: ['A wide range of connection options, materials and surface treatments come into consideration for different applications.HANSA‑FLEX therefore sees comprehensive face-to-face advice on site as very important in finding the perfect solution in every field of use. By doing this, every customer benefits from the decades of experience gained by our fluid technology technicians and specialists across many industries.'],
  }

  const ProductPipebendimagetext7 = {
    image: AllConnections,
    listItems: ["90° ORFS, ISO 8434-3, tumbling process and rotary forming", "24° sealing cone, ISO 8434-1/DIN 2353", "Double-edge cutting ring, DIN 3861", "Brake pipe and coupling connections type D-E-F, DIN 74234", "Flare 37° sealing cone JIC (Triple-Lok), ISO 8434-2/SAE J 514", "Upsetting as bead for spring band clamp DIN 3021-3"],
  }

  //  FeatureCardData

  const featuresData = [
    {
      title: "Highest quality and precision",
      description: "HANSA‑FLEX relies on state-of-the-art machines and computer-controlled manufacturing processes",
      image: imgMark
    },
    {
      title: "Rapid availability",
      description: "Orders for one-off or series-manufactured components fulfilled equally quickly.",
      image: imgMark
    },
    {
      title: "All commonly available forms",
      description: "Customers can obtain all commonly available connection variants for their pipe connections from HANSA‑FLEX.",
      image: imgMark
    },
    {
      title: "Customised manufacture",
      description: "HANSA‑FLEX manufactures special fittings for systems and machines precisely to the customer’s requirements.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];
  return (
    <div>
      <div>
        <CenterStage
          imageSrc={img_Product_Pipeend_Centerspread}
          title={"BENT PIPES FOR FLUID TECHNOLOGY FROM HANSA‑FLEX"}
          paragraph={"Bent pipes are used not only in tight installation situations but also for complex pipework systems. HANSA‑FLEX has set up a pipe bending centre which all branches of the company can access. The HANSA‑FLEX pipe bending centre manufactures bent precision pipes from a variety of materials for all our branches. The pipes are available with all common connection types and in the proven HANSA‑FLEX quality..."}
        />



      </div>

      <SectionHeader
        title={"THE SPECIALISTS FOR PIPE-END PROCESSING AND CONNECTIONS"}
        subtitle={"HANSA‑FLEX has the know-how and technology to implement many different requirements for pipe-end processing, including standard and special solutions as one-off items or in batch production"}
      />


      <div >

        <ImageText data={ProductPipebendimagetext1} left={false} />
      </div>

      <div >

        <ImageText data={ProductPipebendimagetext2} />
      </div>

      <div >

        <ImageText data={ProductPipebendimagetext3} left={false} />
      </div>

      <SectionHeader
        title={"PIPES PROCESSED PRECISELY TO MEET YOUR REQUIREMENTS"}
        subtitle={"As a leading manufacturer of components for the fluid technology sector, HANSA‑FLEX has the know-how and production resources to make customised pipe-ends using a variety of processes."}
      />


      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      <SectionHeader
        title={"SPECIAL FITTINGS MANUFACTURED BY HANSA‑FLEX"}
        subtitle={""}
      />

      <div >

        <ImageText data={ProductPipebendimagetext4} left={false} />
      </div>

      <div >

        <ImageText data={ProductPipebendimagetext5} />
      </div>

      <div >
        <ImageText data={ProductPipebendimagetext6} left={false} />
      </div>

      <SectionHeader
        title={"ALL CONNECTIONS FROM THE SAME SUPPLIER"}
        subtitle={"Customers can buy all commercially available connections from HANSA‑FLEX. These include anything from the traditional cutting ring to various formed metal solutions, and even specially designed connections on request."}
      />
      <ImageText data={ProductPipebendimagetext7} />


      <SectionHeader
        title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
      />
      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProductPipeendimagecard1} learnMoreLink="/hydraulic-hoses" title="HANSA‑FLEX flange fittings are the ideal fittings for large" desc="HANSA‑FLEX flange fittings are ideal for large diameters, high-pressure applications and restricted installation situations." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgProductPipeendimagecard2} learnMoreLink="/metal-hoses" title="Made-to-measure bent pipes" desc="Precision pipes in proven HANSA‑FLEX quality are manufactured made-to-measure out of various materials in the HANSA‑FLEX pipe bending centre." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProductPipeendimagecard3} learnMoreLink="/Industrial-hoses" title="Connected by pipe fittings" desc="Transitions between various components are always a point for careful consideration in hydraulic systems. Pipe fittings from HANSA‑FLEX create a reliable connection." />
          </Col>

        </Row>
      </Container>



    </div>
  )
}

export default PipeendProcessing