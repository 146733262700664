import React from 'react'
import img2017 from '../../assets/Annual_Report/gbericht-17.jpg'
import img2018 from '../../assets/Annual_Report/GB_mockup2018.jpg'
import img2019 from '../../assets/Annual_Report/HANSA-FLEX_Annual_Report_2019.jpg.jpg'
import img2020 from '../../assets/Annual_Report/HANSA-FLEX-Geschaeftsbericht-2020.png'
import ImageText from '../../Components/ImageText/ImageText'
import TextCaption from '../../Components/TextCaption/TextCaption'
import CenterStage from '../../Components/CenterStage/CenterStage'
import imgStage from '../../assets/Annual_Report/stageImg.png'
import SectionHeader from '../../Components/SectionHeader/SectionHeader'

const AnnualReports = () => {

    const data2017 = {
        image: img2017,
        description: "We are pleased to be able to present you with another annual report that not only describes the range of our services and products and the high level of commitment and capability of our now almost 4,000 employees worldwide, but also, as in previous years, shows an economically successful financial year in figures.",
    }

    const data2018 = {
        image: img2018,
        description: "The economic tailwind that many of our customers felt in the 2018 nancial year enabled us to build on the good business performance of previous years. At our 440 branches worldwide more than 4,000 employees in 42 national companies successfully implemented a wide range of projects with our customers and also successfully expanded our traditional core business – pre-assembled spare parts in hydraulic connection technology. In a competitive environment, our group sales grew by a pleasing 10.3% and we were also able to further improve our prots.",
    }

    const data2019 = {
        image: img2019,
        description: "The HANSA-FLEX Group continues to grow as planned in Germany and worldwide – even if the momentum of this development slowed down somewhat in line with the global economic environment in 2019. Last year, more than 4,200 employees at 458 locations and 397 mobile service vehicles worldwide provided expert support to over 156,300 customers worldwide. In addition to engineering consultancy services, this included our 24-hour on-site emergency service, standard spare-parts business over the counter at our branches, and just-in-time deliveries from our central hose line production facility.",
    }

    const data2020 = {
        image: img2020,
        description: "For HANSA-FLEX, as for many other companies, the 2020 financial year was a turbulent one, full of challenges, including economic ones.  A slight decline in revenue for the Group was to be expected after ten years of continuous growth in the face of the global crisis. This decline turned out to be quite moderate at around 6%, with earnings remaining clearly positive. In the first quarter of 2021, however, HANSA-FLEX AG posted record sales. With a monthly increase of 7.4% on average compared to the already very strong sales of the first months of the previous year before the outbreak of the Corona crisis, a new monthly record turnover of a good 31 million euros can be reported for March 2021 in Germany alone.",
    }

    return (
        <div>
            <div>
                <CenterStage 
                    imageSrc={imgStage}
                    title={"ANNUAL REPORT"}
                    paragraph={"A Comprehensive Overview of Our Performance, Growth, and Future Prospects."}
                />
            </div>
            <div>
                <SectionHeader title={"Annual Report 2020"} />
                <ImageText data={data2020} />
                <br />
                <TextCaption paragraph={"The annual report documents HANSA-FLEX's determined joint approach to the challenges posed by the Corona pandemic and provides interesting insights into the parallel development steps of the major company-wide projects. In 2020, despite the difficult circumstances, HANSA-FLEX AG began construction of a new extension to the company's headquarters at its Bremen site (investment volume more than 22 million euros), with Europe's largest automated high-bay warehouse for hose goods, as well as a hose line series production facility with glass production. Numerous national subsidiaries, such as in Poland, Austria, the Czech Republic, Italy and Estonia, were expanded or enlarged, and the PHOENIX global organization project, the centerpiece of HANSA-FLEX's digitalization strategy, was launched. "} />
                <TextCaption paragraph={"The figures section contains the most important key figures on business activities, including the Group's global consolidated revenue. This amounted to around 440 million euros in 2020 on a consolidated basis, due to the Corona crisis, compared to 469 million euros in 2019. In parallel with this moderate decline of 6.6%, the absolute operating result also fell slightly, but remains at a very solid positive level of around 24 million euros (previous year: 25.8 million euros)."} />
                <TextCaption paragraph={`"We have come through the first year of the Corona crisis with a thoroughly satisfactory result," sums up Christian-Hans Bültemeier, Commercial Director of HANSA-FLEX AG. "We owe this development primarily to the truly impressive commitment of our employees, but also to our very broadly based business model and our crisis-proof customer structure with over 150,000 active customers from a wide range of industries."`}
                    buttonName={"Download Now"}
                    buttonLink={"GB_2020.pdf"}
                />
            </div>

            <div>
                <SectionHeader title={"Annual Report 2019"} />
                <ImageText data={data2019} left={false} />
                <br />
                <TextCaption title={`"We are focusing on the further development of digital solutions."`} paragraph={"We are particularly pleased with the strong growth of our 40 foreign subsidiaries. We now successfully support many German customers globally with our well-known efficient performance and individual support. Word gets around quickly and, in many countries, local companies are also increasingly contacting us – not least because of our international operations and because, as a German company, we enjoy a quality advantage. As a result, our recently established companies in India and Singapore have been able to establish themselves very quickly. We are the market leaders in numerous countries and are growing organically at a strong rate – examples include Austria, the Netherlands, Poland as well as Lithuania and Latvia, where we offer the complete range of services and products as a hydraulics system partner, just as we do in Germany."} />
                <TextCaption paragraph={"In Germany, we further expanded and automated our production facilities, while the branch network and our fleet of rapid service vehicles were also expanded. We are growing at an above-average rate, especially in the industrial hydraulic services sector. Above all, we were able to further consolidate and expand our base of satisfied customers, and carry out a range of interesting projects, as regularly reported in our quarterly HYDRAULIKPRESSE magazine."} />
                <TextCaption title={`"We are particularly pleased with the strong growth of our 40 foreign subsidiaries. We successfully support customers around the world."`} paragraph={"However, a main focus of the management is on the development of digital solutions – with regard to our customers as well as to all the relevant internal processes. The further development of the My.HANSA-FLEX customer portal, for example, has been extremely well received; our customers benefit enormously from simplified processes and compliance-relevant documentation solutions that can be perfectly integrated. This portal has been relaunched on a modern software platform and integrated into our webshop and online catalogue environment."} />
                <TextCaption paragraph={"Last year, we internally transferred our computer centres which we had previously operated ourselves in Bremen and to which more than 30 foreign subsidiaries are connected via our joint SAP R/3 ERP solution, to an externally managed cloud service operated by a global service provider. In the current year, as part of a global project, we are preparing the migration of this key resource to the new, updated SAP S/4 system in order to be strategically well prepared for further growth."} />
                <TextCaption title={`"Strategically, we are well prepared for further growth."`}
                    paragraph={"We would like to take this opportunity to thank all our customers who have made this positive development possible, and whom we assure of our continued professional support in the future. We could not have achieved this without the efforts of our large, highly motivated and experienced workforce, to whom we are also extremely grateful."}
                    buttonName={"Download Now"}
                    buttonLink={"GB_2019.pdf"}
                />
            </div>

            <div>
                <SectionHeader title={"Annual Report 2018"} />
                <ImageText data={data2018} />
                <br />
                <TextCaption paragraph={`True to one of our internal guiding principles, which is that “We are all responsible for sales”, our global team successfully marketed our extensive range of products and services, always with a solutions-oriented customer focus. In 2018 we acquired many new customers as a result of our excellent supply capability even in times of high demand. As before, existing customers were able to rely one hundred percent on continuous supplies in all areas. Our mobile services (standard industrial services and 24-hour emergency services) made an above-average contribution to this success which, in absolute terms, continues to have a rock-solid basis in our branch network, which is also growing.`} />
                <TextCaption paragraph={"In addition to our investment in new employees we have pressed ahead with extensive digital projects, which will be available online from 2019 and 2020. The service vehicles will be connected online, our catalogues, web presence and customer portal will be repositioned technologically and their functions will be signicantly expanded in order to increase utility customer by means of integrated, digital solutions."} />
                <TextCaption paragraph={"In a dedicated additional production facility in Dresden, for the first time at HANSA-FLEX two bending robots now operate completely autonomously or – in the case of extra-long pipelines of up to 6 m in length – as a duo, ensuring fully automatic production of customer-specific bent pipelines at any time of the day. Close to the company headquarters in Bremen, we invested in a large logistics facility in order to secure sufficient growth reserves for the coming years. Internationally, we have gained a very good foothold in England since our foundation last year, and have entered the market in Ireland, India and Singapore, and business is showing promise. In those countries abroad where we have been operating for some time we have been able to further expand our market positions – in some cases becoming the market leaders thanks to strong growth."} />
                <TextCaption paragraph={"We would like to take this opportunity to thank all our customers, who are of course the basis for this positive development – and especially our highly committed workforce, who have made our shared success possible in the first place by keeping the focus on the customer and the level of motivation high."}
                    buttonName={"Download Now"}
                    buttonLink={"GB_2018.pdf"}
                />
            </div>

            <div>
                <SectionHeader title={"Annual Report 2017"} />
                <ImageText data={data2017} left={false} />
                <br />
                <TextCaption paragraph={"This success is based above all on the trust our customers place in us. We are growing strongly with new, innovative services for our existing customers, with whom we jointly develop individual solutions and thus strengthen the partnership relationship in the long term. Our numerous trade fair appearances and our good reputation in the industry also enable us to build up new customer relationships on a regular basis. This is often decided by the greater flexibility and speed of our team, combined with a highly regarded delivery performance and material availability. Of course, we are also benefiting from the generally good economic situation, which generated growth for most of our customers in the 2017 financial year, in which we were able to participate."} />
                <TextCaption paragraph={"Last year, HANSA-FLEX focused increasingly on digitization and set up forward-looking projects. Not only to make processes 'behind the scenes' more productive, but also to create new service offerings that make our customers' business easier and more profitable across all industries. These projects tie up high internal capacities, are nevertheless well invested in a competitive environment, and will continue to guarantee the high level of implementation competence that every customer expects from his partner."} />
                <TextCaption paragraph={"Our business in Germany and abroad continued to grow organically. Some of the national companies developed excellently and further expanded their local market leadership. Of course, there are also some countries where we are not yet sufficiently exploiting the market potential. We are growing faster abroad than in Germany; England got off to a good start in 2017 and we are currently working on new companies in Ireland and India. "} />
                <TextCaption paragraph={"In Germany, we have better coordinated our production areas of special valves and two pipe bending centres in terms of processes and will further increase productivity with an additional plant building in 2018. We have also succeeded in achieving significant growth in industrial services and in successfully expanding our services and thus our active customer base. "} />
                <TextCaption
                    paragraph={"Our thanks naturally go to our customers for their partnership and trust - but also expressly to all our motivated employees, who have made the success we have achieved possible in the first place with their high level of commitment and constant customer focus."}
                    buttonName={"Download Now"}
                    buttonLink={"GB_2017.pdf"}
                />
            </div>
            <br /><br />
        </div>
    )
}

export default AnnualReports