import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgLogistics_Centerspread from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { Container, Row, Col } from 'react-bootstrap';
import imgLogistics_Imagecard_01 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_01.jpg'
import imgLogistics_Imagecard_02 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_02.jpg'
import imgLogistics_Imagecard_03 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_03.jpg'
import imgLogistics_Imagecard_04 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_04.jpg'
import imgLogistics_Imagecard_05 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_05.jpg'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import imgLogistics_Imagetext_01 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagetext_01.jpg'
import imgLogistics_Imagetext_02 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagetext_02.jpg'
import imgLogistics_Imagecard_06 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_06.jpg'
import imgLogistics_Imagecard_07 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_07.jpg'
import imgLogistics_Imagecard_08 from '../../../assets/Services/Logistics solutions/Logistics solutions/Logistics_Imagecard_08.jpg'



const Logisticssolutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;



  const featuresData = [
    {
      title: "Optimised use of resources",
      description: "You save time on logistics and production while reducing material and warehousing costs.",
      image: imgMark
    },
    {
      title: "Highest fluid technology competence",
      description: "As a manufacturer and dealer in fluid technology, we offer you tried and tested practical solutions.",
      image: imgMark
    },
    {
      title: "Excellent availability",
      description: "We always deliver on time and are confident of covering sudden demand peaks.",
      image: imgMark
    },
    {
      title: "Maximum flexibility",
      description: "We can work with you to redefine the type and scope of our services whenever you wish.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];

  const Logistics_Imagetext_01 = {
    image: imgLogistics_Imagetext_01,
    title: 'Face-to-face advice and forward planning',
    listItems: ['We greatly value good and long-term relationships with our customers. The first step for us is always a face-to-face consultation, where we meet you on site to jointly agree the optimum solution for your present situation. We always think one step ahead and work with you to design flexible, future-proof solutions. As your company grows over time and your volume of orders gradually increases, we discuss the situation with you and modify your logistics concept to take this into account. This approach ensures you always have the best solution available at any time and in any situation.'],
  }

  const Logistics_Imagetext_02 = {
    image: imgLogistics_Imagetext_02,
    title: 'Digital solutions',
    listItems: ['Digitalised processes are essential for companies with high order volumes if they wish to achieve more efficient arrangements for material supply. We support you in these aims, among other things with scanner solutions and apps capable of speeding up your ordering process. Find out more about our digital services.'],
  }










  return (
    <div>

      <CenterStage
        imageSrc={imgLogistics_Centerspread}
        title={"CUSTOMISED LOGISTICS SOLUTIONS FROM HANSA‑FLEX"}
        paragraph={"Gain time and save costs with customised logistics solutions from HANSA‑FLEX. We work with you to create an optimum logistics concept for the procurement of fluid technology components, flexibly adjustable to your changing requirements. We take into account your current warehouse stock, your requirements situation and your development goals, so that we can give you the best possible support."}
      />

      <SectionHeader
        title={"OPTIMUM COMPONENT PROCUREMENT"}
        subtitle={"As a dealer and system partner, HANSA‑FLEX has the necessary knowledge to adjust your logistics optimally to the requirements of your company."}
      />






      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={8} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgLogistics_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Optimise warehouse management with HANSA‑FLEX" desc="Optimise your goods inventory and bring sustainable order to your equipment stores with a systematically designed warehouse management system. Eliminate time wasted by unnecessary searching and sorting out. Reduce material and procurement costs by working with standardised article lists." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgLogistics_Imagecard_02} learnMoreLink="/metal-hoses" title="Setting up kanban delivery" desc="The HANSA‑FLEX kanban system is the ideal solution for a consistently structured production system and a very extensive requirement for parts. It will help you reduce" />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgLogistics_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Kitting service for fluid technology components" desc="Save assembly and installation time on your production floor by having HANSA‑FLEX supply the hydraulic and pneumatic components you require preassembled into customised installation kits." />
          </Col>






        </Row>
      </Container>

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgLogistics_Imagecard_04} learnMoreLink="/hydraulic-hoses" title="Mobile hose manufacture in Hydraulic Workshop Containers" desc="You can manufacture customised hose lines yourself at any time and at any location using our Hydraulic Workshop Containers to keep standstill times to a minimum. The containers are equipped with tools, hoses" />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgLogistics_Imagecard_05} learnMoreLink="/metal-hoses" title="HANSA‑FLEX is there on site with our works-in-works service" desc="Our employees with their integrated manufacturing workshop are available to work at your premises. Our highly capable technicians manufacture the required" />
          </Col>
        </Row>
      </Container>

      <SectionHeader
        title={"LOGISTICS SOLUTIONS PERFECTLY TAILORED TO YOUR REQUIREMENTS"}
        subtitle={"Each of our solutions is individually designed to maximise the efficiency of your ordering and warehousing processes for fluid technology components and thus increase your productivity."}
      />


      <SectionHeader
        title={"LOGISTICS SOLUTIONS THAT GROW WITH YOU"}
        subtitle={"HANSA‑FLEX supports you from the very beginning with flexible logistics solutions capable of adjustment time and time again to your changing needs as your company develops."}
      />

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      <div >
        <br />
        <ImageText data={Logistics_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Logistics_Imagetext_02} />
      </div>


      <SectionHeader
        title={"WHAT SATISFIED CUSTOMERS SAY"}
        subtitle={"As a system partner, HANSA‑FLEX impresses customers in Germany and abroad with high-quality fluid technology components and well thought out logistics processes."}
      />


      [4/2 10:39 AM] 90000692
      {/* TODO: QUOTE_Carrousel */}

      <SectionHeader
        title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
        subtitle={""}
      />

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgLogistics_Imagecard_06} learnMoreLink="/hydraulic-hoses" title="Assembly and installation" desc="Discover the HANSA‑FLEX range of services for assembly and installation, renovation, optimisation and the planning of new hydraulic systems." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgLogistics_Imagecard_07} learnMoreLink="/metal-hoses" title="Preventative maintenance" desc="Regular inspections of your systems and their components by the experts at HANSA‑FLEX Industrial Service are a sure way of preventing faults and standstills." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgLogistics_Imagecard_08} learnMoreLink="/Industrial-hoses" title="Rapid help in an emergency" desc="Unplanned standstills must be dealt with as quickly as possible. The HANSA‑FLEX emergency service stands ready to do this all round the clock, 365 days a year." />
          </Col>






        </Row>
      </Container>












    </div>
  )
}

export default Logisticssolutions