import React, { useEffect } from 'react';
import CertificateCard from '../../Components/CertificateCard/CertificateCard';

import imgBelgium_20152 from '../../assets/Certificates/Belgium_20152.jpg';
import imgBelgium_2015 from '../../assets/Certificates/Belgium_2015.jpg';
import imgBelgium_2018 from '../../assets/Certificates/Belgium_2018.jpg'



function Certificates() {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <CertificateCard title={"Certification DIN EN ISO 9001-2015 "} description={"The TÜV Austria technical inspection service has certified that HANSA-FLEX applies a management system in compliance with EN ISO 9001:2008 in accordance with the TÜV AUSTRIA CERT procedure for the Development, production, sale, technical consultancy and services relating to fluids technology.This applies to all branches, business divisions and the company group as a whole, together with its branches in the following countries: Germany, Belgium, Botswana, Brazil, Bulgaria, Canada, Croatia, Estonia, France, India, Ireland, Latvia, Lithuania, Luxembourg, Netherlands, Austria, Poland, Romania, Switzerland, Slovakia, Spain, South Africa, Czech Republic, Turkey, Ukraine, Hungary, USA, United Arab Emirates."} imageUrl={imgBelgium_2015} />
            <CertificateCard title={"Certification DIN EN ISO 14001:2009"} description={"HANSA-FLEX successfully achieved ISO 14001 certification, demonstrating its commitment to environmental management. This certification, awarded after an audit by Germanischer Lloyd, covers our Pipebending Centre, Special Pipe and Fittings Production, Metal Hose Production, general hose line production, and corporate headquarters in Bremen, including the central warehouse and quality assurance departments."} imageUrl={imgBelgium_20152} />
            <CertificateCard title={"Certificate DIN EN ISO 45001:2018"} description={"HANSA-FLEX has achieved ISO 45001:2018 certification for Occupational Health and Safety in our Mobile Services sector, including Industrial and Rapid Hydraulic Services. This certification aims to minimize hazards and reduce accidents, ensuring all employees return home in the same condition as when they arrived. Safety is a collective responsibility at HANSA-FLEX."} imageUrl={imgBelgium_2018} />
           
        </div>
    );
}

export default Certificates;