import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import './JourneyCardR.css'; // Import the CSS file for custom styling
import AOS from 'aos';
import 'aos/dist/aos.css';

function JourneyCardR({ title, description, imageUrl, additionalDescription }) {
    const isMobile = window.innerWidth <= 789;

    useEffect(() => {
        AOS.init({
            // Global settings:
            disable: false,
            startEvent: 'DOMContentLoaded',
            initClassName: 'aos-init',
            animatedClassName: 'aos-animate',
            useClassNames: false,

            // Settings that can be overridden on per-element basis:
            offset: 400,
            delay: 0,
            duration: 500,
            easing: 'ease',
            once: false,
            mirror: false,
            anchorPlacement: 'top-bottom',
        });
    }, []);

    return (
        <div className="journey-card-r ">
            <Row>

                {
                    isMobile ?
                        <></> : <><Col className="no-gutters right" data-aos="fade-up">
                            <div className="additional-desc-r ">
                                <div className='vertical-line-point-r'></div>

                                <p>{additionalDescription}</p>
                            </div>
                        </Col></>
                }

                <Col className="no-gutters image-container-div" data-aos={isMobile ? "fade-up" : "fade-left"}>
                    <div className=" text-white j-card-container-r ">
                        <div className="image-container-r">
                            <img src={imageUrl} alt="Journey" />
                            <h2 className="j-img-title-r">{title}</h2>
                        </div>
                        <div>
                            <p className="j-img-description-r">{description}</p>
                        </div>
                    </div>
                </Col>

            </Row>
        </div>
    );
}

export default JourneyCardR;
