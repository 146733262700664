import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import HFTab from '../../../../Components/HfTabs/HfTabs'
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../../assets/mark.png'
import ImageText from '../../../../Components/ImageText/ImageText'

import Stage from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/Stage.jpg'
import SafeOperation from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/SafeOperation.jpg'
import CertifiedManufacturing from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/CertifiedManufacturing.jpg'
import Inspection from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/Inspection.jpg'
import Documentation from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/Documentation.jpg'
import Marking from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/Marking.jpg'
import HoseManagement from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/HoseManagement.jpg'
import ManufacturerService from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/ManufacturerService.jpg'
import Instructions from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/Instructions.jpg'
import img01 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/01.jpg'
import img02 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/02.jpg'
import img03 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/03.jpg'
import img04 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/04.jpg'
import img05 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/05.jpg'
import img06 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/06.jpg'
import IndustrialHose from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/IndustrialHose.jpg'
import MetalHose from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/MetalHose.jpg'
import HoseManagement2 from '../../../../assets/Services/PreventativeMaintainance/IndustrialHoseInspection/HoseManagement2.jpg'
import ShowcaseCarousel from '../../../../Components/ShowcaseCarousel/ShowcaseCarousel';

const IndustrialHoseInspection = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: SafeOperation,
        title: 'For the safe operation of industrial hose lines',
        description: 'In order to ensure industrial hose lines operate safely and reliably, they must be inspected during manufacture and at regular intervals within the framework of recurring inspections in accordance with the German Industrial Safety Act (BetrSichV) by an officially authorised person.',
    }

    const data2 = {
        image: CertifiedManufacturing,
        title: 'Certified manufacture in hose technology centres of excellence',
        description: "If your hose lines require to be replaced, we are happy to manufacture high-quality replacements of all kinds in our certified specialist facilities. HANSA‑FLEX AG is a specialist company in accordance with the German Water Resources Act (WHG) and is certified for quality management in accordance with DIN EN ISO 9001:2015, for environmental management in accordance with DIN EN ISO 14001:2015 and for health and safety at work in accordance with OHSAS 18001:2007.",
    }

    const data3 = {
        listItems: [
            "Steam hose lines",
            "Chemical hose lines",
            "Hydraulic hose lines",
            "Composite hose lines",
            "Food hose lines",
            "Industrial hose lines",
            "Tar spraying hose lines",
            "Grout delivery hose lines",
            "Product delivery hose lines",
            "Cleaning equipment hose lines",
            "Hose winders with suitable hose lines",
            "Pneumatic hose lines",
            "Further tests on request. We look forward to receiving your enquiry."
        ]
    }

    const data4 = {
        image: Instructions,
        description: "One test uses nitrogen and therefore requires some discussion in advance. The site and documentation must be prepared as follows:",
        listItems: [
            "Transport docket for used hose lines in accordance with T 002",
            "Power supply 380V/16A",
            "Connection for the test medium water (fresh or service water)",
            "Compressed air connection (min. 8 bar)",
            "Drainage facilities for the contaminated test medium after completion of the pressure test (chemical separator)",
            "Frost-free work place"
        ]
    }

    const featuresData = [
        {
            title: "Services available anywhere",
            description: "The professional all-round service by officially authorised persons for inspection is available anywhere you wish.",
            image: imgMark
        },
        {
            title: "Shorter standstills",
            description: "Hose line removal, inspection and reinstallation timed to suit you.",
            image: imgMark
        },
        {
            title: "Convenient and quick",
            description: "No transport necessary, official inspection record and stickers are available immediately.",
            image: imgMark
        },
        {
            title: "Attractive overall package",
            description: "Mobile inspection, hose management, guidance on inventory stock and advice all from the same source.",
            image: imgMark
        }
    ];


    //HfTab data
    const imageTextData = [
        {
            title: 'Professional inspections',
            description: "We come on site with our inspection vehicle and carry out all the required inspections on the day you wish including:",
            listItems: [
                "Visual inspection of hose lines and fittings",
                "Internal inspection using an inspection camera",
                "Test of electrical conductivity",
                "Strength test"
            ],
            image: Inspection
        },
        {
            title: 'Complete documentation',
            description: "The inspection certificate for the recurring inspections in accordance with BetrSichV are produced directly in the inspection vehicle. Inspection reports are sent digitally. Paper versions are available on request.",
            image: Documentation
        },
        {
            title: 'Unique marking',
            description: "The prescribed labels are produced directly on the inspection vehicle in the form of inspection stickers and coloured identification label strips and immediately applied to the hose lines.",
            image: Marking
        },
        {
            title: 'Hose management included',
            description: "Every inspected hose line can be coded with X-CODE from HANSA‑FLEX. You are granted free-of-charge access to our X-CODE Manager hose management software (SMART licence for two users).",
            image: HoseManagement
        },
        {
            title: 'Manufacturer-independent service',
            description: "As you would expect, we inspect and code hoses made by other manufacturers if you wish. Our industrial hose facilities teams are also available at any time to perform inspections on site. Give us a call!",
            image: ManufacturerService
        },
    ];

    const tabTitles = [
        { title: "Inspections", eventKey: "Inspections" },
        { title: "Documentation", eventKey: "Documentation" },
        { title: "Marking", eventKey: "Marking" },
        { title: "Hose management", eventKey: "Hose management" },
        { title: "Manufacturer-independent service", eventKey: "Manufacturer-independent service" },
    ];

    const carouselItems = [
        {
            imgSrc: img01,
            description: 'HANSA-FLEX mobile hose inspection unit in use. It has everything on board for the inspection and testing of line technology in accordance with BetrSichV.'
        },
        {
            imgSrc: img02,
            description: 'An officially authorised person for inspections from the HANSA-FLEX Industrial Service team performs a conductivity test in a chemicals industry business park in northern Germany'
        },
        {
            imgSrc: img03,
            description: 'The technician uses an endoscope to perform the internal inspection of a chemical hose line.'
        },
        {
            imgSrc: img04,
            description: 'The mobile inspection vehicle always his an engraving tool on board for marking the components'
        },
        {
            imgSrc: img05,
            description: 'The marking applied to a chemical hose line is easy to see.'
        },
        {
            imgSrc: img06,
            description: 'Hose management with X-CODE Manager The coding and the inspection dates are entered on site by the service technician into the customer specific database.'
        },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"INSPECTION OF LINE EQUIPMENT IN ACCORDANCE WITH BETRSICHV"}
                    paragraph={"HANSA‑FLEX inspects your industrial hose lines in accordance with the German Industrial Safety Act (BetrSichV). We perform inspections as an on-site service from our mobile inspection service vehicles or in our centres of excellence for hose technology [KD1] [MUM2] . We inspect hose lines made from various materials used to carry food, chemicals, pharmaceuticals and other media."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"OUR ALL-ROUND SERVICE FOR YOUR MAINTENANCE"}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"PROFESSIONAL INSPECTION AND DOCUMENTATION"}
                    subtitle={"We inspect industrial hose lines in elastomeric and thermoplastic materials, composite hoses, smooth and corrugated non-metallic hoses and corrugated metal hoses. We also inspect:"}
                />
                <ImageText
                    data={data3} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"WE INSPECT ON SITE AT DATES TO SUIT YOUR REQUIREMENTS"}
                    subtitle={"Benefit from our mobile all-round service. HANSA‑FLEX comes to your premises with a fully equipped inspection vehicle driven by an officially approved person for inspections in accordance with BetrSichV/TRBS 1203 and carries out all the inspections."}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"INSTRUCTIONS FOR PREPARING FOR MOBILE INDUSTRIAL HOSE INSPECTIONS"}
                    subtitle={"To ensure that our service runs as smoothly as possible on site, we request that you note the following: the hose lines to be inspected must be flushed out and free of residual media, the maximum test pressure of the vehicle is 100 bar, the test medium is..."}
                />
                <ImageText
                    data={data4} />
            </div>

            <div>
                <SectionHeader
                    title={"YOUR BENEFITS WITH MOBILE INDUSTRIAL HOSE INSPECTION FROM HANSA‑FLEX"}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"INDUSTRIAL HOSE INSPECTION SERVICES"}
                />
                <ShowcaseCarousel carouselItems={carouselItems} isExploreButton={false} descDisplay='block' carouselType={2} />
            </div>

            <div>
                <SectionHeader
                    title={"CODING AND HOSE MANAGEMENT FOR INDUSTRIAL HOSE LINES"}
                    subtitle={"Use the coding system, proven millions of times in the field of hydraulics, for the preventive maintenance of your industrial hose lines. X-CODE and the X-CODE Manager software give you the optimum overview of all your hose lines, machines, scheduled dates and documentation."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"MORE SERVICES FOR YOUR HYDRAULIC SYSTEMS"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={IndustrialHose}
                                title="Industrial hoses for many areas of use"
                                desc={"Whether it is chemicals, foodstuff, abrasive media or fuels: we have a suitable industrial hose and can preassemble customised hose lines for you."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={MetalHose}
                                title="Metal hose lines for the most demanding requirements"
                                desc={"Customer solutions for particularly challenging and safety-relevant applications, manufactured to the highest precision, from a one-off component to series production."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={HoseManagement2}
                                title="Hose management with X-CODE Manager"
                                desc={"Find out more about the convenient software for the best overview of your hose lines and inspection dates. Try out the free-of-charge demo version."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default IndustrialHoseInspection 