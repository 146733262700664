import React from 'react'

import CenterStage from '../../Components/CenterStage/CenterStage';
import ContactDetails from '../../Components/ContactDetails/ContactDetails';
import imgbuilding from '../../assets/Whoweare/building.jpg'

const Antwerpen = () => {
    const companyInfo = {
        name: 'HANSA-FLEX Hydraulics NV',
        address: 'Industriepark-Drongen 12, 9031 Gent, Belgium',
        email: 'ant@hansa-flex.be',
        phone: '+32 3 542 10 10',
        googleMapsUrl: 'https://www.google.com/maps/place/HANSA-FLEX+Hydraulics+n.v./@51.0436782,3.597023,17z/data=!3m1!4b1!4m6!3m5!1s0x47c36e77763a504f:0x4ae08bb44c4fd344!8m2!3d51.0436782!4d3.597023!16s%2Fg%2F1td9v897?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D'
    };
  return (
    <div>
 <CenterStage
                imageSrc={imgbuilding}
                title={"HANSA-FLEX BELGIUM - Antwerpen"}
                paragraph={"Welcome to the Antwerpen unit of HANSA-FLEX Belgium, inaugurated in ......"}
            />



<div>
                <ContactDetails companyInfo={companyInfo} />
            </div>


    </div>
  )
}

export default Antwerpen