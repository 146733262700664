import React from 'react';
import MissionStatementComponentCard from './MissionStatementComponentCard';
import './MissionStatementComponentCard.css'; // Ensure the CSS file is imported here

const MissionStatementComponentCardGrid = ({ data, columns }) => {
    return (
        <div className="MissionStatementComponentCard-grid" style={{ '--columns': columns }}>
            {data.map((item, index) => (
                <MissionStatementComponentCard key={index} image={item.image} title={item.title} listItems={item.listItems} />
            ))}
        </div>
    );
};

export default MissionStatementComponentCardGrid;
