import React, { useEffect } from 'react';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import TextCaption from '../Components/TextCaption/TextCaption';
import { Link } from 'react-router-dom';

const TermsofPurchase = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
}, []);
  return (
    <div>
      <SectionHeader title="HANSA-FLEX AG GENERAL TERMS OF PURCHASE (Valid from: 15.06.2016)" />
      <TextCaption
        title="1. Applicable contractual terms"
        paragraph={[
          "a. These general terms of purchase regulate the fundamental legal relationships between the supplier and HANSA-FLEX AG (referred to below as: HANSA-FLEX) with respect to all goods and services which are ordered.",
        ]}
      />

      <TextCaption
        paragraph={
          "b. In the absence of any other express agreements these terms of purchase will have sole application. No supplementary verbal agreements have been concluded."
        }
      />

      <TextCaption
        paragraph={
          "c. Contrary terms of purchase will not apply, even if they have not been expressly rejected in individual cases. In this case statutory regulations will have sole application."
        }
      />

      <TextCaption
        paragraph={
          "d. Our terms of purchase will also apply to all future transactions with the supplier."
        }
      />

      <TextCaption
        title="2. Written form"
        paragraph={[
          "a. As a rule our orders will be placed in written or electronic form. If an order is placed verbally (or by telephone) it must be confirmed in text form at least, for purposes of proof.",
        ]}
      />

      <TextCaption
        paragraph={
          "b. All documents provided by the supplier, e.g. confirmation of order, consignment note and invoice must contain the HANSA-FLEX order data and in particular the HANSA-FLEX article designation. Any consequences resulting from the failure to comply with the above will be the responsibility of the supplier."
        }
      />
      <TextCaption
        paragraph={
          "c. The cash discount periods described under Section 8 do not begin until after the receipt of an invoice containing all the data required by us, together with the documents which enable the secure acquisition of ownership (e.g. freight or shipping documents, consignment notes etc.)."
        }
      />
      <TextCaption
        paragraph={"d. Deliveries to different business units must not be grouped together in one invoice item."
        }
      />

      <TextCaption
        title="3. Prices and delivery costs"
        paragraph="Unless agreed otherwise the supplier will be responsible for transport and acceptance costs. If HANSA-FLEX has specified a specific method of transport, any additional costs arising from failure to meet these shipping instructions will be the responsibility of the supplier."
      />

      <TextCaption
        title="4. Delivery times"
        paragraph={[
          "a. The delivery times specified by HANSA-FLEX in orders are binding. Goods which are ordered must be received by HANSA-FLEX on the specified delivery date or within the required delivery period, while services must be implemented by the agreed date.",
        ]}
      />
      <TextCaption
        paragraph={
          "b. Any delay in delivery identified by the supplier, including delays on the part of third party suppliers, must be reported to HANSA-FLEX without delay. The agreement of HANSA-FLEX to an extension of the delivery period must be obtained. If the supplier fails to provide notification, the supplier will automatically be in arrears with delivery once the specified period is exceeded. Other reasons for delay will not be affected."
        } />
      <TextCaption
        paragraph={
          "c. In the case of failure to meet the agreed delivery time HANSA-FLEX will have the right, without prejudice to its other statutory claims, to refuse to take delivery of the goods or services, even without providing an extension period, and to withdraw from the contract or, if the supplier is in arrears with delivery and is responsible for the delay, to claim compensation for failure to provide performance, compensation for damage arising from defects, and/or performance of the supplier’s contractual obligations."
        } />

      <TextCaption
        title="5. Transfer of risk"
        paragraph="The risk of accidental destruction or deterioration with respect to the goods is transferred at the earliest after the delivery has been accepted at the relevant business unit (delivery obligation). If customer acceptance has been agreed, this will determine the point at which the risk is transferred."
      />


      <TextCaption
        title="6. Quality management"
        paragraph="The supplier must monitor the quality of the goods or services provided on a continuous basis. Before the delivery of the goods or services to be supplied, the supplier must check that they are free from defects, meet the agreed technical requirements, and are packed securely for transport. In particular the supplier must warrant that all goods supplied meet the latest safety and protective regulations and norms. The supplier will comply with the agreed quality assurance system."
      />

      <TextCaption
        title="7. Warranty"
        paragraph={[
          "a. The rights of HANSA-FLEX in relation to material and legal defects and any other breach of obligations on the part of the supplier will be subject to the applicable statutory regulations, unless specified otherwise below",
        ]}
      />

      <TextCaption
        paragraph={
          "b. In accordance with statutory regulations the supplier will be liable in particular for ensuring that the goods have the agreed characteristics at the point at which the risk is transferred to HANSA-FLEX. The agreed characteristics will be those product descriptions which – in particular by their designation or stipulation in the HANSA-FLEX order – are the subject of the individual contract or, like these terms of purchase, form an integral part of the contract. In this respect it will be irrelevant whether the product description has been provided by HANSA-FLEX or by the supplier."
        } />

      <TextCaption
        paragraph={
          "c. The warranty period will be 36 months from the date of delivery."
        } />

      <TextCaption
        paragraph={
          "d. The commercial obligation to inspect the goods and report any deficiencies will be in accordance with the applicable statutory regulations (§§ 377, 381 German Commercial Code; HGB). In all cases, any report of deficiencies made by HANSA-FLEX will be regarded as having made without delay and in good time if it reaches the supplier within a period of five working days from the receipt of the goods or, in the case of hidden defects, from their discovery."
        } />

      <TextCaption
        title="8. Liability for defects"
        paragraph={[
          "a. In warranty cases the supplier undertakes, at the choice of HANSA-FLEX, to provide either improvements or a replacement delivery within a reasonable period set by HANSA-FLEX. In the event of failure by the supplier to meet its obligations within this period HANSA-FLEX will have the right to remedy the defect itself or have it remedied by third parties, and to demand from the supplier compensation for the costs involved or demand the corresponding advance payment and/or, at the choice of HANSA-FLEX, to assert its statutory right to withdraw from the contract and claim subsequent performance, reduction in price or compensation, including compensation for defects and the consequences of such defects. This is without prejudice to the right of the supplier to reject the form of subsequent performance selected in accordance with the preconditions of § 439 Section 3 P. 1 BGB.",
        ]}
      />

      <TextCaption
        paragraph={
          "b. If the supplier meets its subsequent performance obligations in the form of a replacement delivery, the warranty period of 36 months stipulated in 7 c) will begin from receipt of the new delivery, unless the supplier has expressly implemented such subsequent performance for purposes of goodwill, to avoid any dispute or in the interests of the continuation of the supply relationship."
        } />

      <TextCaption
        paragraph={
          "c. This is without prejudice to the other statutory rights of HANSA-FLEX."
        } />

      <TextCaption
        paragraph={
          "d. Regardless of the rights described above, the supplier undertakes to indemnify HANSA-FLEX to the legal extent against claims from third parties arising from any withdrawal from the contract, subsequent performance, reduction in price or compensation in relation to defects to the supplied goods. This will not apply if the defects had not been present on the transfer of risk. The above claim for indemnification will also not apply to any claims made in relation to an assurance provided by HANSA-FLEX to its own customers if this assurance does not correspond to the assurances made by the supplier."
        } />

      <TextCaption
        paragraph={
          "e. The supplier must insure against such claims for defects by concluding product liability insurance and confirm the existence of this insurance to HANSA-FLEX on request."
        } />

      <TextCaption
        title="9. Supplementary terms"
        paragraph="Payment will be implemented after delivery of the goods or services in their entirety and without defects as follows:"
      />

      <TextCaption
        paragraph={
          "a. Receipt of invoice and goods from the 1st to the 15th of the month – on the 25th of the same month with 3% cash discount."
        } />

      <TextCaption
        paragraph={
          "b. Receipt of invoice and goods from the 16th to the end of the month – on the 10th of the following month with 3% cash discount."
        } />

      <TextCaption
        paragraph={
          "c. If the 25th or 10th of a month are not working days, payment will be affected on the following working day."
        } />

      <TextCaption
        title="10. Rights of setoff, retention, assignment"
        paragraph={[
          "a. We will have the right to set off all claims to which we or a different HANSA-FLEX business unit have against the supplier and against all claims which the supplier has against us, regardless of their legal grounds.",
        ]}
      />
      <TextCaption paragraph={
        "b. This will also apply if performance has been agreed by one party in the form of financial payment and by the other party in bills of exchange or other form."
      } />

      <TextCaption paragraph={
        "c. The agreements may apply to the balance of the claims. If claims are due on different dates, the value on the due date will be applied."
      } />

      <TextCaption paragraph={
        "d. The supplier may not assign contractual claims to third parties without our consent. This is without prejudice to the provisions of § 354a HGB."
      } />

      <TextCaption paragraph={
        "e. In the event of a defect subject to warranty HANSA-FLEX will have the right to withhold part of the payment corresponding to the level of the defect until the defect has been corrected."
      } />

      <TextCaption paragraph={
        "f. For its part the supplier will only have the right of setoff against our claims or to assert a right of retention if and to the extent that its claim is undisputed or has been recognised by a court of law."
      } />

      <TextCaption
        title="11. Place of fulfilment, jurisdiction and applicable law"
        paragraph={[
          "a. The place of fulfilment relating to the provision of goods and services is Bremen for both parties, unless expressly agreed otherwise. This will apply to both domestic and foreign service providers. Bremen will also be the sole place of jurisdiction for all disputes.",
        ]}
      />
      <TextCaption paragraph={
        "b. These terms of purchase and all legal relationships between HANSA-FLEX and the supplier are subject to the laws of the Federal Republic of Germany, to the exclusion of international uniform law, in particular the United Nations Convention on Contracts for the International Sale of Goods (CISG). "
      } />


      <TextCaption
        title="12. Final provisions"
        paragraph={[
          "a. In the event of insolvency, liquidation or termination of payments on the part of the supplier we will have the right to withdraw from the contract. In cases of force majeure we will have the right to suspend the contract in whole or in part or require implementation at a later point in time, without this leading to claims on the part of the supplier.",
        ]}
      />
      <TextCaption paragraph={
        "b. If individual provisions of our terms of purchase are or become invalid, this will not affect the validity of the remaining terms. In accordance with the (supplementary) interpretation of the contract, whatever provision come closest to the invalid provision will apply."
      } />


      <div style={{ marginTop: '20px', marginLeft: '15%', textAlign: 'left' }}>
        <p style={{ marginTop: '0px', marginBottom: '0px' }}>HANSA-FLEX AG</p>
        <p style={{ marginTop: '0px', marginBottom: '0px' }}>Zum Panrepel 44</p>
        <p style={{ marginTop: '0px', marginBottom: '0px' }}>28307 Bremen</p>
        <p style={{ marginTop: '0px', marginBottom: '0px' }}>Germany</p>

        <br />
        <li> <a href="/HANSA-FLEX_AEB_EN.pdf" target="_blank" rel="noopener noreferrer" className="footer-link"> Download (PDF) HANSA-FLEX AG General Terms of Purchase</a></li>
      </div>
      <br /><br /><br />
    </div>
  )
}

export default TermsofPurchase