import React from 'react';
import './ImageDescription.css'; // Import your custom CSS file for styling

const ImageDescription = ({ title, subtitle, paragraphs, imageSrc, alignment }) => {
    const containerStyle = {
        textAlign: alignment || 'center'
    };

    return (
        <div className="custom-component-container" style={containerStyle}>
            {title && <h1 className="custom-title">{title}</h1>}
            {imageSrc && <img src={imageSrc} alt="Custom Image" className="custom-image" />}
            <p className="custom-subtitle">{subtitle}</p>
            {paragraphs.map((paragraph, index) => (
                <p key={index} className="custom-paragraph">{paragraph}</p>
            ))}
        </div>
    );
};

export default ImageDescription;
