import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './CertificateCard.css'; // Import your custom CSS file for styling
import { FaDownload } from 'react-icons/fa';


const CertificateCard = ({ title, description, imageUrl }) => {
    return (
        <div className="image-info-container">
            <Row className='crt-row-container'>
                <Col md={6} className='crt-image-container-wrapper'>
                    <div className="crt-image-container">
                        <img src={imageUrl} alt={title} className="crt-img-fluid" />
                    </div>
                </Col>
                <Col md={6}>
                    <div className="crt-image-info">
                        <h2 className="crt-title">{title}</h2>
                        <p className="crt-description">{description}</p>
                        {/* <span className="download-text">Download</span>
                        <FaDownload className="download-icon" /> */}
                    </div>
                </Col>
            </Row>
            <hr className="faint-horizontal-line" />

        </div>
    );
};

export default CertificateCard;
