import React, { useEffect } from 'react';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import TextCaption from '../Components/TextCaption/TextCaption';

const Disclaimer = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    return (
        <div>
            <SectionHeader title={"Terms and Conditions"} titleSize={"2.5rem"}
                subtitle={"Terms and Conditions of HANSA-FLEX NV, as of 06.03.2023"}
            />

            <TextCaption title={"1. Toepassingsgebied"} bulletPoints={[
                "1.1. Deze algemene voorwaarden (hierna de 'Voorwaarden') zijn van toepassing op alle offertes, bestellingen of overeenkomsten opgesteld door of gesloten met de NV 'HANSA-FLEX HYDRAULICS', met zetel te 9031 Drongen, Industriepark-Drongen 12, ingeschreven in het RPR te Gent, afdeling Gent en met btw-nummer 0454.891.101 (hierna de 'Onderneming').",
                "1.2. Door het plaatsen van een bestelling, het aanvaarden van een offerte of het sluiten van een overeenkomst verklaart de klant of de opdrachtgever (hierna de 'Klant') uitdrukkelijk kennis te hebben genomen van de Voorwaarden van de Onderneming, deze te begrijpen, ze te aanvaarden en er een exemplaar van te bezitten.",
                "1.3. De Voorwaarden van de Onderneming zijn van toepassing op zowel ondernemingen in de zin van art. I.1, 1° van het Wetboek van Economisch Recht, als consumenten in de zin van art. I.1, 2° van het Wetboek van Economisch Recht.",
                "1.4. De Voorwaarden van de Onderneming dienen exclusief te worden opgevat. Bijgevolg stemt de Onderneming niet in met de toepasselijkheid van enige andere algemene voorwaarden van de Klant, ongeacht of door de Klant daar naar verwezen wordt in enige andere correspondentie of deze aan de Onderneming worden toegestuurd.",
                "1.5. Deze Voorwaarden maken samen met (desgevallend) de offerte, bestel- of werkbon, koopovereenkomst, de integrale overeenkomst uit (hierna de 'Overeenkomst') en geeft het akkoord tussen partijen volledig en uitsluitend weer wat betreft het voorwerp van de overeenkomst en heft alle voorgaande mondelinge en/of schriftelijke overeenkomsten, mededelingen, aanbiedingen, voorstellen of briefwisseling tussen partijen met betrekking tot hetzelfde voorwerp op.",
                "1.6. Afwijkingen van deze Voorwaarden zijn slechts geldig indien deze door de Onderneming schriftelijk zijn bevestigd in de Overeenkomst."
            ]} />

            <TextCaption title={"2. Offertes en aanbiedingen"} bulletPoints={[
                "2.1. Alle door de Onderneming verstrekte offertes en aanbiedingen zijn vrijblijvend en binden de Onderneming niet. De Onderneming behoudt zich het recht voor om wijzigingen aan te brengen in vorm, kleur, gewicht en wijzigingen van technische aard binnen de grenzen van wat redelijk is.",
                "2.2. Indien de Onderneming voor het opstellen van de offerte opmetingen heeft gedaan bij de Klant, en deze bevestigt de offerte door de bestelling te plaatsen, dan is de Klant ertoe gehouden de kosten gemaakt door de Onderneming voor de opmeting te vergoeden, tenzij anders overeengekomen. In het andere geval is de Klant hiertoe niet gehouden."
            ]} />

            <TextCaption title={"3. Bestellingen en opdrachten"} bulletPoints={[
                "3.1. Elke bestelling door de Klant is bindend en onherroepelijk en impliceert een uitdrukkelijke kennisname en aanvaarding van de Voorwaarden, zelfs zo de bestelling niet schriftelijk gebeurt of wanneer ze door Klant niet is ondertekend.",
                "3.2. De prijzen die van toepassing zijn op de bestelling, zijn deze gebaseerd op de thans geldende waarden van lonen en materialen. De Onderneming houdt zich het recht voor om de prijzen op ieder moment te verhogen in geval van (i) een stijging van de grondstofprijzen, (ii) een verhoging van de overheidsheffingen op de bestelde producten of (iii) een wijziging in de wetgeving inzake de lonen.",
                "3.3. Bij ontvangst van een bestelling start de Onderneming onmiddellijk met de uitvoering ervan. Indien de Klant zijn bestelling annuleert, zullen de reeds uitgevoerde werken worden gefactureerd."
            ]} />

            <TextCaption title={"4. Levering en verzending"} bulletPoints={[
                "4.1. Levering van een gekocht goed of afgifte van een te herstellen goed of enig goed dat na de dienstverlening van de Onderneming terug aan de Klant dient te worden bezorgd, vindt naargelang het geval plaats op de zetel of een vestiging van de Onderneming.",
                "4.2. Indien dit goed niet wordt opgehaald binnen 8 dagen na de dag dat de Onderneming aan de Klant heeft medegedeeld dat deze klaar is voor levering/afgifte, geschiedt de bewaring op risico van de Klant.",
                "4.3. De Onderneming verleent de Klant bemiddeling bij verzending van de goederen. Het vervoer geschiedt steeds op risico van de Klant en de Onderneming aanvaardt geen enkele verantwoordelijkheid inzake schade en verlies tijdens de bevrachting en verzending."
            ]} />

            <TextCaption title={"5. Termijnen"} bulletPoints={[
                "Alle leverings- en andere termijnen zijn steeds bij benadering en zonder enige resultaatsverbintenis van de Onderneming. De niet-naleving ervan kan geen aanleiding geven tot schadevergoeding van de Overeenkomst."
            ]} />

            <TextCaption title={"6. Prijzen"} bulletPoints={[
                "Tenzij expliciet anders vermeld ten aanzien van Klanten, zijn de vermelde prijzen exclusief BTW, invoerrechten, belastingen en/of andere rechten of heffingen die van overheidswege zijn of worden opgelegd."
            ]} />

            <TextCaption title={"7. Betaling"} bulletPoints={[
                "7.1. Alle facturen zijn contant betaalbaar op de vervaldatum (hierna de 'Factuurdatum') op de zetel van de Onderneming of via bankoverschrijving en zijn verschuldigd netto zonder disconto en zonder enige afhouding.",
                "7.2. De termijn voor protest tegen een factuur verstrijkt 8 kalenderdagen na Factuurdatum. Protest dient steeds per aangetekend schrijven te gebeuren aan het adres van de zetel van de Onderneming.",
                "7.3. Bij niet-betaling van de factuur op de vervaldatum is van rechtswege en zonder voorafgaandelijke ingebrekestelling een forfaitaire vergoeding ten belope van 10% van het nog verschuldigde factuurbedrag (met een minimum van € 100,00) als schadebeding én een verwijlinterest berekend cfr. artikel 5 van de wet van 2 augustus 2002 betreffende de betalingsachterstand bij handelstransacties, verschuldigd door de Klant en dit onverminderd het recht van de Onderneming om een hogere vergoeding te vorderen mits bewijs van hoger geleden schade.",
                "7.4. Zonder schriftelijke toestemming van de Onderneming is de Klant niet gerechtigd tot verrekening of opschorting van de nakoming van de op haar rustende betalingsverplichting.",
                "7.5. Betaling wordt eerst aangerekend op de invorderingskosten, vervolgens op de verschuldigde schadevergoeding, vervolgens op de verschuldigde interest en daarna op de hoofdsom. Indien de Klant meerdere facturen onbetaald laat, wordt een betaling, met inachtneming van het bepaalde in de vorige zin, eerst in mindering gebracht van de oudste factuur en vervolgens van de op één na oudste factuur, enzovoort.",
                "7.6. De Onderneming kan gebruik maken van een voorschot. Indien de Onderneming een voorschot verzoekt van de Klant, dient deze onmiddellijk contant aan de Onderneming te worden voldaan. Indien het voorschot niet tijdig door de Onderneming is ontvangen, heeft de Onderneming het recht de levering van het goed of de dienst op te schorten tot het moment van ontvangst van betaling."
            ]} />

            <TextCaption title={"8. Eigendomsvoorbehoud"} bulletPoints={[
                "8.1. De Klant verkrijgt slechts de eigendom van het goed, ongeacht de aard van de overeenkomst, op het ogenblik van de volledige betaling van de prijs, in hoofdsom en bijhorigheden. De Klant is niet gerechtigd om de eigendom van het goed te verkopen, te verhuren, in pand te geven of er ten gelijk welke titel over te beschikken en/of er enige verandering aan te brengen die de waarde ervan zou kunnen verminderen zolang de prijs niet integraal voldaan werd.",
                "8.2. Het risico op verlies en ontwaarding van de goederen gaan evenwel over vanaf de levering en ontvangst van de goederen door de Klant."
            ]} />

            <TextCaption title={"9. Overdracht van rechten en hoofdelijkheid"} bulletPoints={[
                "9.1. Elke Overeenkomst met de Onderneming is strikt persoonlijk en mag door de Klant niet worden overgedragen aan een derde zonder voorafgaandelijke en schriftelijke toestemming van de Onderneming.",
                "9.2. De Onderneming is gerechtigd zijn aanspraken op betaling van vergoedingen aan een derde over te dragen.",
                "9.3. Indien de Klant bestaat uit meerdere natuurlijke personen en/of rechtspersonen, is elk van die personen hoofdelijk gehouden tot nakoming van de verplichtingen die staan opgenomen in de met of ten behoeve (van één of meer) van hen gesloten Overeenkomst."
            ]} />

            <TextCaption title={"10. Waarborgen"} bulletPoints={[
                "10.1. Zichtbare gebreken moeten door de Klant binnen 2 weken na ontvangst schriftelijk aan de Onderneming worden gemeld.",
                "10.2. In geval van gebrek van een geleverd goed zal de Onderneming, naar keuze, het gebrek herstellen of een vervanging voorzien. Indien de Onderneming niet in staat is het gebrek te herstellen of in een vervanging te voorzien mag de Klant, naar keuze, een prijsvermindering vragen of de Overeenkomst ontbinden. De aansprakelijkheid van de Onderneming is beperkt zoals voorzien onder artikel 12 van deze Voorwaarden.",
                "10.3. De waarborg geboden door de Onderneming geldt voor één jaar na de levering van de goederen aan de Klant, tenzij de Klant de gebreken niet tijdig heeft gemeld overeenkomstig artikel 10.1 of indien het gebrek opzettelijk of door nalatigheid is veroorzaakt door de Onderneming.",
                "10.4. De waarborg geboden door de Onderneming gaat niet boven deze die door de fabrikant(en) geboden wordt. De waarborg is in ieder geval beperkt tot de vervanging van de gebrekkige goederen door equivalente goederen. De contractuele waarborgen dekken in ieder geval niet (i) uiterlijke gebreken vastgesteld door de Klant of die redelijkerwijze dienden te worden vastgesteld door de Klant na het aanvaarden van de levering (ii) het abnormaal gebruik of niet conform gebruik van de producten zoals een normaal en voorzichtig persoon in dezelfde omstandigheden geplaatst (lees aandachtig de gebruiksaanwijzingen welke steeds kunnen worden bekomen bij de Onderneming op vraag van de Klant), (iii) gebreken en hun gevolgen veroorzaakt door een gebruik niet conform aan dit waarvoor het product bestemd is, (iv) gebreken en hun gevolgen te wijten aan elke externe oorzaak, (v) interventies en herstellingen door een derde uitgevoerd op het product.",
                "10.5. Enige wettelijke garantie of waarborgverplichting in het voordeel van een Klant blijft onverminderd van toepassing."
            ]} />

            <TextCaption title={"11. Beëindiging"} bulletPoints={[
                "Bij contractuele wanprestatie, faillissement of kennelijk onvermogen van de Klant, kan de Onderneming de overeenkomst zonder enige ingebrekestelling en van rechtswege als ontbonden beschouwen bij eenvoudig aangetekend schrijven. In geval van ontbinding of verbreking van de Overeenkomst ten laste van de Klant, zal deze aan de Onderneming een forfaitaire schadevergoeding verschuldigd zijn van 25% van de te factureren prijs van de Overeenkomst, onverminderd de vergoeding van de werkelijke schade van de Onderneming. In geval van ontbinding van de Overeenkomst ten laste van de Onderneming, zal de Klant, gerechtigd zijn op dezelfde schadevergoeding."
            ]} />

            <TextCaption title={"12. Aansprakelijkheid"} bulletPoints={[
                "12.1. De aansprakelijkheid van de Onderneming jegens de Klant is beperkt tot hetgeen in deze Voorwaarden is geregeld.",
                "12.2. Tenzij uitdrukkelijk anders overeengekomen, zijn al de verbintenissen van de Onderneming middelenverbintenissen. Onverminderd afwijkende wettelijke bepalingen, is de Onderneming slechts aansprakelijk voor schade veroorzaakt wegens de niet-naleving van haar verbintenissen, indien en voor zover de schade is veroorzaakt door haar opzettelijke of zware fout of voor bedrog. Voor overige fouten is de Onderneming niet aansprakelijk.",
                "12.3. De aansprakelijkheid van de Onderneming is beperkt tot maximaal de factuurwaarde van de bestelling van de Klant, althans tot dat gedeelte van de bestelling waarop de aansprakelijkheid betrekking heeft. De Onderneming is uitsluitend aansprakelijk voor directe schade. De Onderneming is nooit aansprakelijk voor indirecte schade, met inbegrip van doch niet uitsluitend gevolgschade, gederfde winst, gemiste besparingen of schade aan derden."
            ]} />

            <TextCaption title={"13. Overmacht"} bulletPoints={[
                "13.1. De Onderneming is niet aansprakelijk jegens de Klant voor enige tekortkoming in of ontijdige uitvoering van of voor de gevolgen van enige tekortkoming in of niet-tijdige uitvoering van de Overeenkomst indien deze voortkomt uit een gebeurtenis die niet te wijten is aan haar schuld, noch krachtens wet, rechtshandeling of geldende opvattingen voor haar rekening komt, zoals onder meer bij epidemie, natuurrampen, oorlogen, arbeidsgeschillen, protestacties, brand, oproer, explosies, terroristische acties en nationale calamiteiten.",
                "13.2. In geval van overmacht wordt deze Overeenkomst ontbonden en zijn de partijen niet langer gehouden tot uitvoering van hun verbintenissen onder deze Overeenkomst."
            ]} />

            <TextCaption title={"14. Algemene bepalingen"} bulletPoints={[
                "14.1. Onderhavige Voorwaarden zijn noodzakelijk om de rechtmatige belangen van partijen te beschermen. Iedere bepaling van deze Voorwaarden die ongeldig, nietig of onafdwingbaar zou zijn wegens strijdigheid met enige wetsbepaling van dwingend recht of openbare orde, zal desalniettemin haar maximaal toegelaten gevolg ressorteren en laat de toepassing van de overige Voorwaarden ongemoeid. In geval van nietigheid, zal kunnen worden heronderhandeld over het vervangen van de nietige clausule door een andere die zo nauw mogelijk aansluit bij de bedoeling van de nietige bepaling.",
                "14.2. De Klant erkent in het kader van (de uitvoering van) de Overeenkomst in het bezit te kunnen komen van vertrouwelijke informatie van de Onderneming. Deze vertrouwelijke informatie blijft de exclusieve eigendom van de Onderneming en mag zonder haar voorafgaande schriftelijke toestemming niet openbaar worden gemaakt, aan een derde worden verstrekt of anderszins worden gebruikt voor enig ander doel dan de uitvoering van de Overeenkomst.",
                "14.3. De Onderneming behoudt zich het recht voor om uitsluitend voor intern gebruik gegevens van de Klant te verzamelen. Overeenkomstig de toepasselijke nationale en Europese wetgeving inzake gegevensbescherming, heeft de Klant te allen tijde het recht om die gegevens in te zien, te wijzigen en te laten verwijderen. Hiervoor kan de Klant schriftelijk contact opnemen met de Onderneming.",
                "14.4. Voor enige interpretatie van de Voorwaarden, hebben onze Nederlandstalige Voorwaarden voorrang op enige Voorwaarden opgesteld in een andere taal dan het Nederlands.",
                "14.5. Op alle Overeenkomsten gesloten tussen de Onderneming en haar Klanten is uitsluitend het Belgisch recht van toepassing. Toepasselijkheid van het Weens Koopverdrag 1980 is uitgesloten. Eventuele geschillen zullen worden voorgelegd aan de rechtbanken te Gent."
            ]} />

            <div style={{ marginTop: '20px', marginLeft: '22%', textAlign: 'left' }}>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>HANSA-FLEX NV</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Industriepark Drongen 12</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>9031 GENT-DRONGEN</p>
                <p style={{ marginTop: '0px', marginBottom: '0px' }}>Belgium</p>
            </div>
            <br /><br /><br />
        </div>
    )
}

export default Disclaimer