import React, { useEffect } from 'react';
import CenterStage from '../../Components/CenterStage/CenterStage';
import stage from '../../assets/Whoweare/stage-2.jpg'
import { MdEmail, MdFax, MdLocationOn, MdPhone } from 'react-icons/md';
import { Button, Col, Row } from 'react-bootstrap';
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import NumberGrid from '../../Components/NumberGrid/NumberGrid';
import imgSouthAfrica from "../../assets/Whoweare/SouthAfrica.png";
import FullImageText from '../../Components/FullImageText/FullImageText';
import imgBremen from "../../assets/Whoweare/Bremen.jpg"
import Promoter from '../../Components/Promoter/Promoter';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import FeatureCardCarousel from '../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import hose from '../../assets/Whoweare/HoseLogo.png';
import imgServiceVan from '../../assets/servicevan.png'
import imgCartons from '../../assets/Whoweare/Cartons.png';
import imgCustomerSupport from '../../assets/Whoweare/customer-support.png';
import imgService from '../../assets/Whoweare/service.jpg';
import imgLogistics from '../../assets/Whoweare/LogisticsImg.jpg';
import ShowcaseCarousel from '../../Components/ShowcaseCarousel/ShowcaseCarousel';
import imgBelgium1 from '../../assets/Whoweare/Belgium1.jpg';
import ContactDetails from '../../Components/ContactDetails/ContactDetails';
import ImageText from '../../Components/ImageText/ImageText';
import imgAnnualReport from '../../assets/Whoweare/annualReport.jpg';
// import imgAnnualReportPDF from '';

const AboutMiddleEast = () => {
    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //hall of fame
    const numbers = ['50+', '100.000+', '1350+'];
    const subtitles = ['No. of Employees', 'No. of Annual productions', 'No. of Customers'];


    // const sealingTech4 = {
    //     image: SealTechnology,
    //     listItems: ["Standard and special seals", "Hydraulic and pneumatic seals", "Static seals", "Sealing rings, flat seals and moulded parts", "Measuring aids for determining the right seal", "Assembly tool"],
    // }

    //promoter content



    // features card carousel data
    const featuresData = [
        {
            title: "Customer Service",
            description: "Offering dedicated assistance and guidance to our clients in Belgium.",
            image: imgCustomerSupport
        },
        {
            title: "Hydraulic Industrial Service",
            description: "Custom services on demand.",
            image: imgServiceVan
        },
        {
            title: "Premium Quality products",
            description: "Experience our unmatched Quality.",
            image: hose
        },
        {
            title: "Extensive Stock Availability",
            description: "We have stocks of products around 13000 units!",
            image: imgCartons
        }
        // Add more objects for additional FeaturesCard data
    ];
    const carouselItems = [
        {
            imgSrc: imgService,
            title: 'Fast and efficient Hydraulic Spare Parts Supply',
            description: 'At HANSA-FLEX Belgium, we provide best of customer service and take care that the quality product is delivered.'
        },

        {
            imgSrc: imgLogistics,
            title: 'Advanced Engineering Integration ',
            description: 'HANSA‑FLEX Belgium designs and manufactures innovative drive and control systems right up to the project planning of entire hydraulic installations.'
        }
    ];

    const data = {
        image: imgAnnualReport,
        title: "Anual Report",
        description: "We invite you to download our latest company report here.",
        buttonName: "Download Now",
        buttonLink: 'annualReport.pdf'
    }

    const companyInfo = {
        name: 'HANSA-FLEX Hydraulics NV',
        address: 'Industriepark-Drongen 12, 9031 Gent, Belgium',
        email: 'sales@hansa-flex.be',
        phone: '+32 11 22 02 02',
        googleMapsUrl: 'https://www.google.com/maps/place/HANSA-FLEX+Hydraulics+n.v./@51.0436782,3.597023,17z/data=!3m1!4b1!4m6!3m5!1s0x47c36e77763a504f:0x4ae08bb44c4fd344!8m2!3d51.0436782!4d3.597023!16s%2Fg%2F1td9v897?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D'
    };

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgBremen}
                    title="Welcome to HANSA-FLEX Belgium – Your Reliable Source for Top-Quality Serial Hose Production"
                    paragraph="At HANSA-FLEX Belgium, we take pride in being a part of the esteemed HANSA-FLEX Group and are thrilled to introduce our state-of-the-art manufacturing unit established in 1995. With a strong commitment to delivering the highest quality products, we are your go-to destination for premium ferrules and fittings." />

            </div>
            <br /><br />



            <FullImageText
                imageSrc={imgBelgium1}
                altText="Stage"
                title="Our Promise of Quality"
                paragraph="Quality is what we're all about. Our high-tech manufacturing plant is equipped with the latest tools to make sure every product we create is of the highest quality. At HANSA-FLEX Belgium, we know that your success depends on the stuff we make, so we never compromise on quality."
                imageWidth={1}
            />

            {/* <div>
                <ImageText data={data}/>
            </div> */}

            <br />
            <br />

            <div className='div-gray'>
                <SectionHeader title={"Why Choose Us?"} paragraph={"We are the epitome of excellence, renowned for our unwavering commitment to quality and customer satisfaction. We deliver unparalleled solutions tailored to your needs."} />
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <div >
                <SectionHeader
                    title={"Quick Facts about HANSA-FLEX Belgium"} /><br />
                <NumberGrid numbers={numbers} subtitles={subtitles} /><br /><br />
            </div>

            <div className="text-center div-gray">
                <SectionHeader
                    title={"Explore HANSA-FLEX Belgium Locations"}
                    paragraph={"We are part of the renowned HANSA-FLEX AG, a world leader in fluid technology solutions. Located in 6 different locations throughout Belgium, we proudly serve as a cornerstone for hydraulic solutions, seamlessly connecting more than 400 sites worldwide."} />
                <br />
                <Button variant="danger" href={"/contact-us"} target="_self" style={{ boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)' }}>Find the nearest branch</Button>

                <br /><br /><br /><br />
            </div>

            <div>

                <br /><br /><br /><br />
                <ShowcaseCarousel carouselItems={carouselItems} isExploreButton={false} descDisplay='block' carouselType={1} />
            </div>
            {/* <div>
        {isLaptop && (
          <Promoter
            title={promoterTitle}
            checklistItems={promoterChecklistItems}
            // btnLabel1={"Find Out More !"}
            // btnLabel1Link={"/hoses-and-hose-lines"}
            imageUrl={imgBelgiumSustainable}
          />
          // TODO: contact us button
        )}
      </div> */}
            <br /><br /><br />
            <div className="text-center div-gray">
                <SectionHeader
                    title={"Explore about HANSA-FLEX Group"}
                    paragraph={"HANSA‑FLEX is a global family-run company and one of the world’s leading system partners for fluid technology. In the last business year, more than 5,000 employees in 40 countries achieved a turnover of EUR 570 million."} />
                <br />
                <Button variant="danger" href={"https://www.hansa-flex.de/en/company/"} target="_blank" style={{ boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)' }}>Learn more about HANSA-FLEX</Button>

                <br /><br /><br /><br />
                <br /><br /><br /><br />
            </div>
            <br /><br /><br /><br />
            <div>
                <ContactDetails companyInfo={companyInfo} />
            </div>
        </div>
    )
}

export default AboutMiddleEast