import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import { FaLandmark, FaPhoneVolume } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { BiLogoFacebookCircle } from "react-icons/bi";
import Imgxing from '../../assets/xingImg.png'
import Imglinkidin from '../../assets/Linkdin.png'
import Imgyoutube from '../../assets/youtube.png'
import Imgkaggle from '../../assets/kaggleImg.png'
import Imgfacebook from '../../assets/Facebook.png'
import { Link } from 'react-router-dom';
import './HfFooter.css'

const HfFooter = () => {
  return (
    <footer className='hfFooter-wrapper' id='contact-us-section'>
      <Container className='hfFooter-container'>
        <Row className="justify-content-center " style={{ marginLeft: 'auto' }}>
          <Col sm={12} lg={3} >
            <div className='footer-column-div'>
              <b>How to reach us</b>
              <ul>
                <li><a href="https://www.google.com/maps/place/HANSA-FLEX+Hydraulics+n.v./@51.0436782,3.597023,17z/data=!3m1!4b1!4m6!3m5!1s0x47c36e77763a504f:0x4ae08bb44c4fd344!8m2!3d51.0436782!4d3.597023!16s%2Fg%2F1td9v897?entry=ttu&g_ep=EgoyMDI0MDkxOC4xIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer" className="footer-link"><FaLandmark /> HANSA-FLEX Hydraulics NV, Industriepark-Drongen 12,<br /> 9031 Gent,<br /> Belgium</a></li>
                <li><a href="tel: +32 11 22 02 02" className="footer-link"><FaPhoneVolume /> +32 11 22 02 02</a></li>
                <li><a href="mailto:sales@hansa-flex.be" className="footer-link"><MdOutlineEmail /> sales@hansa-flex.be</a></li>
              </ul>
            </div>
          </Col>

          {/* <Col sm={12} lg={3} >
            <div className='footer-column-div'>
              <b>News and events </b>
              <ul>
                <li><a href="tel:+971 (0) 800-42672353" className="footer-link"><MdOutlineEmail /> https://www.hansa-flex.be/subsidiaries.html</a></li>
              </ul>
            </div>
          </Col> */}

          <Col sm={12} lg={3}>
            <div className='footer-column-div'>
              <b>About HANSA‑FLEX</b>
              <ul>
                <li><Link to="/Careers" className="footer-link">Careers</Link></li>
                <li><Link to="/certificates" className="footer-link">Certificates</Link></li>
                <li> <a href="https://www.hansa-flex.de/en/magazine/archive/september-2024/" target="_blank" rel="noopener noreferrer" className="footer-link"> Magazine</a></li>
                <li> <a href="https://www.hansa-flex.de/en/company/" target="_blank" rel="noopener noreferrer" className="footer-link"> More About HANSA-FLEX Group</a></li>
                {/* <li><Link to="/careers" className="footer-link">Careers</Link></li> */}
              </ul>
            </div>
          </Col>


          {/* <Col xs={4} lg={3}>
          <div className='footer-column-div'>
          <h>Service</h>
          <ul>
                <li>Mobile rapid hydraulic service</li>
                <li>Fluid management</li>
                <li>Assembly and installation</li>
                <li>Hydarulic cylinder repair</li>
                <li>Preventive maintenance</li>
            </ul>
          </div>
          </Col> */}

          <Col sm={12} lg={3}>
            <div className='footer-column-div'>
              <b>Popular Categories</b>
              <ul>
                <li><Link to="/hoses-and-hose-lines" className="footer-link">Products</Link></li>
                <a href="https://my.hansa-flex.be/nl_NL/login" target="_blank" rel="noopener noreferrer" className="footer-link">X-CODE Manager</a>
              </ul>
            </div>
          </Col>

          <Col sm={12} lg={3}>
            <div className='footer-column-div'>
              <b>Quicklinks</b>
              <ul>
                <li><Link to="/metal-hoses" className="footer-link">Metal hoses</Link></li>
                <a href="https://shop.hansa-flex.be/nl_NL/slangen/c/webcat_HF_SCH" target="_blank" rel="noopener noreferrer" className="footer-link">Catalog</a>
                <li><Link to="/Industrial-hoses" className="footer-link">Industrial Hoses</Link></li>
                <a href="https://shop.hansa-flex.be/nl_NL/" target="_blank" rel="noopener noreferrer" className="footer-link">Webshop</a>
                <li><Link to="/hydraulic-hoses" className="footer-link">Hydraulic Hoses</Link></li>
                <a href="https://my.hansa-flex.com/de_DE/login" target="_blank" rel="noopener noreferrer" className="footer-link">My.HANSA-FLEX</a>
                
              </ul>
            </div>
          </Col>

        </Row>

        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <h>Follow us</h>
          </Col>
        </Row>

        <Row className="custom-3row ">

          {/*<Col className="circle-col"><div><img src={Imgkaggle} alt="" /></div></Col>
          <Col className="circle-col"><div><img src={Imgxing} alt="" /></div></Col> */}
          <Col ><div className="circle-col"><a href="https://www.linkedin.com/company/hansaflexbelgium/" target="_blank" rel="noopener noreferrer"><div>< img src={Imglinkidin} alt="" /></div></a></div></Col>
          {/* <Col ><div className="circle-col"><a href="https://www.xing.com/pages/hansa-flexag" target="_blank" rel="noopener noreferrer"><div>< img src={Imgxing} alt="" /></div></a></div></Col>
          <Col ><div className="circle-col"><a href="https://www.kununu.com/de/hansa-flex" target="_blank" rel="noopener noreferrer"><div>< img src={Imgkaggle} alt="" /></div></a></div></Col> */}
          <Col><div className="circle-col"><a href="https://www.youtube.com/@HANSAFLEXAG" target="_blank" rel="noopener noreferrer"><div><img src={Imgyoutube} alt="" /></div></a></div></Col>
          <Col><div className="circle-col"><a href="https://www.facebook.com/profile.php?id=61562957267236" target="_blank" rel="noopener noreferrer"><div><img src={Imgfacebook} alt="" /></div></a></div></Col>

        </Row>

        <Row className="line-row">
          <Col className="line-col"></Col>
        </Row>

        <div className='HfFooter-link-div'>
          <a className="hf-footer-link" href="">© Copyright All Rights Reserved</a><span>  |  </span>
          <Link to="/hansa-flex-Imprint" className="footer-link">Imprint</Link><span>  |  </span>
          <Link to="/terms-and-conditions" className="footer-link">Terms and Conditions</Link><span>  |  </span>
          <Link to="/hansa-flex-india-privacy-policy" className="footer-link">Privacy Policy</Link><span>  |  </span>
          <Link to="/hansa-flex-TermsofPurchase" className="footer-link">Terms of Purchase</Link>
        </div>



      </Container>
    </footer >

  );
};

export default HfFooter;
