import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';
import { Col, Container, Row } from 'react-bootstrap';

import imgStage from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/Stage.jpg'
import PowerfullPump from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/PowerfullPump.jpg'
import CompactCylinder from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/CompactCylinder.jpg'
import ValveTech from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/ValveTech.jpg'
import BallTech from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/BallTech.jpg'
import HoseLines from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/HoseLines.jpg'
import Accessories from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/Accessories.jpg'
import HydraulicTools from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/HydraulicTools.jpg'
import VascoDaGamaBridge from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/VascoDaGamaBridge.jpg'
import SafetyInstruction from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/SafetyInstruction.jpg'
import SafetyInstruction2 from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/SafetyInstruction2.jpg'
import dyas from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/dyas.jpg'
import Installation from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/Installation.jpg'
import Over from '../../../assets/Products/DriveAndControlTechnology/HeavyLoadHydraulics/Over.jpg'



const SevenHundredBarHydraulics = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const BarHydraulics1 = {
        image: PowerfullPump,
        title: 'Powerful high-pressure pumps up to 700 bar',
        listItems: ["Hand pumps up to 2.6 l tank capacity", "Electric pump-driven 700 bar hydraulic power units (on request)", "Systems with reduced maximum pressure range (e.g. up to 400 bar)"],
    }

    const BarHydraulics2 = {
        image: CompactCylinder,
        title: 'Compact high-pressure cylinders',
        listItems: ["Single- and double-acting heavy load cylinders and pull cylinders", "Up to 150 mm stroke and 100 t load", "Surface coatings: painted, burnished"],
    }

    const BarHydraulics3 = {
        image: VascoDaGamaBridge,
        title: 'The Vasco da Gama Bridge is protected against earthquakes by hydraulics',
        description: 'With a length of 17.2 km, the Vasco da Gama Bridge in Lisbon is the longest bridge in Europe. Up to 50 million vehicles a year use the six-lane bridge to cross not only the mouth of the Tejo River but also a particularly earthquake-prone area. Working in conjunction with conventional structural measures, hydraulic dampers play a key role in protecting the bridge from earthquakes, storms and other loads.',
    }

    const BarHydraulics4 = {
        image: SafetyInstruction,
        listItems: ["Do not utilise more than 80 % of the equipment capacity.", "Avoid overloading the equipment.", "Use a gauge to check pressures.", "Do not actuate hand levers using tools or extension bars.", "Clean quick release couplers before coupling.", "Always protect hose lines from damage."],
    }

    const BarHydraulics5 = {
        image: SafetyInstruction2,
        listItems: ["Always wear personal protective equipment for hands, eyes and feet.", "Use hydraulic equipment only on a level surface of sufficient load-bearing strength", " Clean equipment after use.", "Always secure loads mechanically.", "Adequately prepare the load set-down areas.", "Do not stand or walk under raised loads."],
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Proven safety",
            description: "All inspection and test reports are provided to you directly.",
            image: imgMark
        },
        {
            title: "Maximum performance",
            description: "We ensure all components, such as pumps and cylinders, are perfectly matched to one another.",
            image: imgMark
        },
        {
            title: "The extra mile",
            description: "We are happy to supply all your components as preconfigured sets ready for immediate installation.",
            image: imgMark
        },
        {
            title: "Flexible use",
            description: "All products can be used in mobile and stationary hydraulic systems.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Shut-off and throttle valves",
            listItems: ["Check and throttle check valves", "Pressure relief valves", "Needle shut-off valves"],
            buttonName: "TO THE VALVES",
            image: ValveTech
        },
        {
            title: "Low-maintenance ball valves",
            listItems: ["Floating ball free to move between the seals", "Large bore keeps pressure losses low", "Maximum volumetric flow: 27 l/min"],
            buttonName: "TO THE BALL VALVES",
            image: BallTech
        },
        {
            title: "Durable hose lines",
            description: "Hose lines designed for pressures up to 700 bar have to resist the highest loads. HANSA‑FLEX, with its many years as a fluid specialist, can preassemble high-quality hoses and fittings into durable high-pressure hose lines. Our branches have all the approvals and the test benches required to perform this work. We ensure the hose and the fitting are perfectly matched to maximise performance. The hose line you receive safely fulfils its functions in the required pressure ranges.",
            buttonName: "VIEW MORE",
            image: HoseLines
        },
        {
            title: "Extensive range of accessories",
            description: "Hydraulic connecting elements, such as adapters, distributors and threaded connectors, are an essential means of joining components in hydraulic systems. HANSA‑FLEX offers an extensive portfolio of connection technology components for high-pressure hydraulics. We can offer complete systems, designed, supplied and installed all from the same source.",
            buttonName: "TO THE ACCESSORIES",
            image: Accessories
        },
        {
            title: "Hydraulic tools",
            description: "mobile workshop presses with high-strength welded frames. We would be happy to provide other hydraulic tools such as vehicle jacks, torque wrenches or nut splitters on request.",
            buttonName: "TO HYDRAULIC TOOLS",
            image: HydraulicTools
        },
    ];

    const tabTitles = [
        { title: "Valve technology", eventKey: "Valve technology" },
        { title: "Ball valves", eventKey: "Ball valves" },
        { title: "Hose lines", eventKey: "Hose lines" },
        { title: "Accessories", eventKey: "Accessories" },
        { title: "Hydraulic tools", eventKey: "Hydraulic tools" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"HANSA‑FLEX COMPONENTS FOR HIGH-PRESSURE HYDRAULICS UP TO 700 BAR"}
                    paragraph={"Moving heavy loads places the highest requirements on the hydraulic components used. HANSA‑FLEX offers a selected range of products for heavy load hydraulics with pressures up to 700 bar and can supply customised components for up to 1,000 bar on request. We also support you with the selection, design and combination of components for maximum performance and the highest levels of safety at work."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"EXCELLENT PERFORMANCE AT HIGH PRESSURES"}
                    subtitle={"All HANSA‑FLEX products intended for high-pressure hydraulic applications comply with the current technical rules and are extensively tested by the manufacturers to ensure they work properly and safely."}
                />
                <ImageText
                    data={BarHydraulics1} />
                <ImageText
                    data={BarHydraulics2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"THE COMPLETE HANSA‑FLEX PRODUCT PORTFOLIO FOR HIGH-PRESSURE APPLICATIONS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"HIGH-CLASS PRODUCTS FROM THE FLUID SPECIALISTS"}
                    subtitle={"With HANSA‑FLEX you can count on receiving first-class quality products and services."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <ImageText
                    data={BarHydraulics3} />
            </div>

            {/* TODO: Carousal */}

            <div>
                <SectionHeader
                    title={"IMPORTANT SAFETY INSTRUCTIONS FOR SYSTEM OPERATION"}
                    subtitle={"Safety is paramount when working with high pressures. This concise summary sets out the most important safety instructions for the use of hydraulic components in high-pressure."}
                />
                <ImageText
                    data={BarHydraulics4} />
                <ImageText
                    data={BarHydraulics5}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"OVERHAULING HIGH-PRESSURE COMPONENTS"}
                    subtitle={"As a system partner, HANSA‑FLEX is there to help and advise you with the servicing of high-pressure components. We also undertake professional repairs, either in-house or with the support of specialist partners."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"THESE PRODUCTS AND SERVICES MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={dyas}
                                title="We are there for you 365 days a year, all round the clock"
                                desc={"In the event of breakdowns or unplanned stoppages, service technicians from the HANSA‑FLEX Rapid Hydraulic Service can be on site immediately in their mobile..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Installation}
                                title="Installation service from HANSA‑FLEX"
                                desc={"Experienced technicians from the HANSA‑FLEX Industrial Service provide quick and reliable support for the installation of components in stationary or mobile hydraulic..."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Over}
                                title="Over 4,500 different hydraulic components in stock"
                                desc={"HANSA‑FLEX supplies an extensive range of hydraulic components: from pumps and motors to cylinders and filters, from valves right up to switches and sensors..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default SevenHundredBarHydraulics