import React from 'react';
import './MissionStatementComponentCard.css';
import { BsCheckLg } from 'react-icons/bs';

const MissionStatementComponentCard = ({ image, title, listItems }) => {
    return (
        <div className="card MissionStatementComponentCard-body">
            <img src={image} alt={title} className="MissionStatementComponentCard-image" />
            <h3 className="MissionStatementComponentCard-title">{title}</h3>
            <ul className="MissionStatementComponentCard-list">
                {listItems.map((item, index) => (
                    <li key={index}><BsCheckLg className="li-promoter" />{item}</li>
                ))}
            </ul>
        </div>
    );
};

export default MissionStatementComponentCard;
