import React, { useEffect } from 'react'
import CenterStage from '../../Components/CenterStage/CenterStage'

import imgStage from '../../assets/Products/TechnicalInformation/stage.jpg'
import SectionHeader from '../../Components/SectionHeader/SectionHeader';
import TextAccordion from '../../Components/TextAccordion/TextAccordion';

const TechnicalInformation = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const items = [
        {
            header: "Quick reference",
            description: "The quick reference brochure provides a brief overview of the most important construction elements, such as thread tables, comparison of the current standard designations with the HANSA‑FLEX designations and much more. Here you will also find our overview of hydraulic symbols.",
            points: [
                {
                    text: "Brochure QUICK REFERENCE",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/Quick_Reference_Folder_EN.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/Gut-zu-wissen.pdf" }
                    ]
                },
                {
                    text: "Poster HYDRAULIC SYMBOLS",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/Poster_Hydraulic-Symbols_2022_EN.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/Poster_Hydrauliksymbole_2022_DE.pdf" }
                    ]
                },
            ]
        },
        {
            header: "Hydraulic hoses and hydraulic technology",
            description: "Information and safety guidelines for the installation, operation, maintenance and inspection of HANSA‑FLEX hydraulic hose lines:",
            points: [
                {
                    text: "Poster HOSE TECHNOLOGY  PDF English",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/Poster_Hose_Technology_2022_EN.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/Poster_Schlauchtechnik_2022_DE.pdf" }
                    ]
                },
                {
                    text: "Technical Information HOSE TECHNOLOGY",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/Technical_Information_Hose_Technology_2022.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/Technische_Informationen_Schlauchtechnik_DE_2022.pdf" }
                    ]
                },
                {
                    text: "Technical Information INDUSTRIAL HOSE LINES",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/TI-Industriel_Hoses_20240319_EN_rz_web_1.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/TI-Industrieschlaeuche_20240319_DE_rz_web_1.pdf" }
                    ]
                },
                {
                    text: "Technical Information METAL HOSES",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/Technical_Information_Metal_Hoses_EN.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/Technische_Informationen_Metallschlaeuche_DE.pdf" }
                    ]
                },
            ]
        },
        {
            header: "Hydraulic connection technology",
            description: "Overview of the technical documentation CONNECTION TECHNOLOGY and ASSEMBLY MANUALS for hydraulics:",
            points: [
                {
                    text: "Technical Information Connection Technology PIPE FITTINGS",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/Technical_Information_Conection_Technology_EN_01.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/TI-Verbindungstechnik_ROV_240319_DE_rz_web.pdf" }
                    ]
                },
                {
                    text: "Technical Information Connection Technology PIPELINES",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/TI-ConnectionTechnology_Pipelines_240319_EN_rz_web.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/TI-Verbindungstechnik_Rohrleitungen_240319_DE_rz_web.pdf" }
                    ]
                },
                {
                    text: "Poster CONNECTION TECHNOLOGY",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/Poster_Connection_Technology_2022_EN.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/Poster_Verbindungstechnik_2022_DE.pdf" }
                    ]
                },
                {
                    text: "Poster CUTTING RING ASSEMBLY",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/Poster_Cutting_Ring_Assembly_2022_EN.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/Poster_Schneidringmontage_2022_DE.pdf" }
                    ]
                },
                {
                    text: "Technical Information 24° CONE CONNECTORS/ COMPRESSION FITTINGS",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/TI_Assembly_instructions_connectors_compression_fittings_240319_EN_rz_web_1__1_.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/TI-Verbindungstechnik_Montage_240319_DE_rz_web.pdf" }
                    ]
                },
                {
                    text: "Technical Information ADAPTER",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/TI-Connection_Technology_Adapters_240319_EN_rz_web.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/TI-Verbindungstechnik_Adapter_240319_DE_rz_web.pdf" }
                    ]
                },
                {
                    text: "Technical Information COUPLINGS",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/TI_Technical_Information_Coupling_EN.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/TI_Technische_Informationen_Kupplungen_DE.pdf" }
                    ]
                },
            ]
        },
        {
            header: "Hydraulic components",
            description: "Overview of technical documentation HYDRAULIC COMPONENTS:",
            points: [
                {
                    text: "Technical Information HYDRAULIC COMPONENTS",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/Technical_Information_Hydraulic_Components_EN.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/Technische_Informationen_Hydraulik_Komponenten_DE.pdf" }
                    ]
                },
                {
                    text: "General instructions for use and operation BALLVALVES",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/TI_Ball_valves_EN_web.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/TI-Kugelhaehne_DE_web.pdf" }
                    ]
                },
                {
                    text: "Technical Information HYDRAULIC CYLINDERS",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/TI-Hydraulic_cylinders_EN_web.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/TI-Hydraulikzylinder_DE_web.pdf" }
                    ]
                },
            ]
        },
        {
            header: "Industrial Technology",
            description: "Overview of technical documentation INDUSTRIAL HOSE LINES:",
            points: [
                {
                    text: "Technical Information Industrial HOSE LINES:",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/TI-Industriel_Hoses_20240319_EN_rz_web_1 (1).pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/TI-Industrieschlaeuche_20240319_DE_rz_web_1 (1).pdf" }
                    ]
                },
            ]
        },
        {
            header: "Metal hoses",
            description: "Overview of technical documentation METAL HOSES:",
            points: [
                {
                    text: "Technical Information METAL HOSES",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/TI-Metallschlaeuche_EN_web.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/TI-Metallschlaeuche_DE_web.pdf" }
                    ]
                },
            ]
        },
        {
            header: "Sealing technology and Sealants",
            description: "Overview of the technical documentation on SEALING TECHNOLOGY and SEALING MATERIALS:",
            points: [
                {
                    text: "Technical Information SEALING TECHNOLOGY",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/Technical_Information_Sealing_Technology_EN.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/Technische_Information_Dichtungstechnik_DE.pdf" }
                    ]
                },
                {
                    text: "Technical Information  SEALINGS",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/Manual_Sealing_Technology_En.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/Manual_Dichtmittel.pdf" }
                    ]
                },
            ]
        },
        {
            header: "Compressed air technology",
            description: "Overview of technical documentation COMPRESSED AIR TECHNOLOGY / PNEUMATICS:",
            points: [
                {
                    text: "Technical Information COMPRESSED AIR TECHNOLOGY",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/Technical_Information_Pneumatic_EN.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/Technische_Inofmationen_Pneumatik_DE.pdf" }
                    ]
                },
            ]
        },
        {
            header: "Fastening Technology",
            description: "Overview of technical documentation FASTENING TECHNOLOGY:",
            points: [
                {
                    text: "Technical Information FASTENING TECHNOLOGY",
                    pdfs: [
                        { language: "English", link: "/PDF_folder/Technical_info/TI_Fastening_Technology_240402_EN_rz_web.pdf" },
                        { language: "German", link: "/PDF_folder/Technical_info/TI_Befestigunstechnik_240319_DE_rz_web.pdf" }
                    ]
                },
            ]
        }
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"FIT FOR PRAXIS"}
                    paragraph={`HANSA‑FLEX offers its customers a range of technical information that is highly relevant in both theory and practice and should be heeded. In our "Good to Know" brochure, for example, we provide a brief overview of the most important design elements of hydraulics. In addition, we offer comprehensive descriptions on the correct assembly, operation and maintenance of hose lines, explain the function and areas of application of a wide variety of tube fittings and point out various sealing techniques and sealants. We will be happy to provide you with personal technical advice.`}
                />
            </div>

            <div>
                <TextAccordion
                    title={"TECHNICAL INFORMATION TO DOWNLOAD"}
                    subtitle={"Here you can find all overviews, technical information and our quick reference brochure for download in German and English as a PDF."}
                    items={items}
                />
            </div>

        </div>
    )
}


export default TechnicalInformation