import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './FullImageText.css'; // import your CSS file
import TextCaption from '../TextCaption/TextCaption';

const FullImageText = ({ imageSrc, altText, title, paragraph, imageWidth }) => {
    const isMobile = window.innerWidth <= 768;
    // Calculate the actual width based on the input
    const getImageWidth = () => {
        switch (imageWidth) {
            case 1:

                return isMobile ? '100%' : '150%';
            case 2: 
                return '100%';
            default:
                return '150%';
        }
    };



    // Check if image source and title are present
    const shouldRenderComponent = imageSrc && title;

    return shouldRenderComponent ? (
        <div className="full-image-text-container">
            <Row className="full-image-text-row">
                <Col md={6} className="full-image-text-column">
                    {/* Left column content */}
                    <div className="overlap-container">
                        <div className="image-wrapper" style={{ width: getImageWidth() }}>
                            <img src={imageSrc} alt={altText} className="full-image" />
                        </div>

                        {isMobile ? <></> : <>

                            <div className="flt-text-content-wrapper">
                                <TextCaption title={title} paragraph={paragraph} version={2} />
                            </div>
                        </>}
                    </div>
                </Col>
                <Col md={6} className="full-image-text-column">

                    {isMobile ? <>
                        <div className="flt-text-content-wrapper">
                            <TextCaption title={title} paragraph={paragraph} version={2} />
                        </div>
                    </> :
                        <></>}

                </Col>
            </Row>
        </div>
    ) : null;
};

export default FullImageText;
