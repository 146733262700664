import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import Stage from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/Stage.jpg'
import WeCanDesign from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/WeCanDesign.jpg'
import DetailedProjectPlanning from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/DetailedProjectPlanning.jpg'
import Implementation from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/Implementation.jpg'
import Renovation from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/Renovation.jpg'
import Installation from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/Installation.jpg'
import TrainingProgramme from '../../../assets/Services/AssemblyAndInstallation/PlantProjectPlanningAndManagement/TrainingProgramme.jpg'

const PlantProjectPlanningAndManagement = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data1 = {
        image: WeCanDesign,
        title: 'We can design a system to meet your requirements or check your drawings',
        description: 'We design your hydraulic system to meet your requirements. If you have already produced your own drawings, we check your proposals. When doing this we pay particular attention to the following:',
        listItems: [
            "Optimum use of the installation space and the hydraulic energy",
            "Selection of the ideal material for the specific requirements",
            "Compliance with permitted bending radii with no imposed torsion",
            "Observance of relevant standards such as DGUV, DNV-GL and EN 45545",
            "Ensure continued availability of spare parts (obsolescence management)"
        ]
    }

    const data2 = {
        image: DetailedProjectPlanning,
        title: 'Detailed project planning to ensure correct timing',
        description: "Our hydraulic experts not only have extensive specialist knowledge, they are also experienced project managers. They help you coordinate the project and work towards achieving completion on time and within budget.",
        listItems: [
            "Creating a project programme",
            "Define realistic and verifiable milestones",
            "Deadlines to keep the project on schedule"
        ]
    }

    const data3 = {
        image: Implementation,
        title: 'Implementation by experienced specialists',
        description: 'In addition to time management, our specialists also undertake resource management, ensuring that all the labour and material required for each work package are available.',
        listItems: [
            "Specialist personnel for all work packages",
            "Experts in tasks that require specific qualifications or experience",
            "Assured material availability",
            "Workshop containers can be made available if necessary"
        ]

    }

    const featuresData = [
        {
            title: "Assessment and transparent advice",
            description: "Our experts properly assess your system and offer fair and competent advice.",
            image: imgMark
        },
        {
            title: "Careful planning",
            description: "Before work starts on renovation, we draw up a detailed project plan including milestones.",
            image: imgMark
        },
        {
            title: "Standards and quality",
            description: "Our experts observe all standards and deliver proven HANSA‑FLEX quality.",
            image: imgMark
        },
        {
            title: "Everything from a single source",
            description: "Excellently trained technicians and the highest material availability.",
            image: imgMark
        }
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"PROJECT PLANNING FOR HYDRAULIC SYSTEMS"}
                    paragraph={"The design and construction of hydraulic systems requires specialist knowledge. The applicable standards and regulations such as DGUV are continually changing. Therefore, companies designing their own hydraulic systems face a big challenge to ensure they are technically up to date and comply with the latest statutory requirements. Our experts have years of experience in this area and support you with the project management of your planned hydraulic system."}
                />
            </div>

            <div>
                <SectionHeader
                    title={'FROM INITIAL ADVICE TO IMPLEMENTATION'}
                    subtitle={'We support you in all phases of the project. We advise you during the planning phase and answer any questions of safety and energy efficiency, provide active support with the design, check your drawings and implement your requirements professionally.'}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
            </div>

            <div>
                <SectionHeader
                    title={"RETROFITTING HYDRAULIC SYSTEMS TO THE LATEST TECHNOLOGICAL STANDARDS"}
                    subtitle={"Retrofitting and renovating hydraulic systems with advice and support from HANSA‑FLEX offers numerous advantages and provides true added value for your company."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            {/* TODO: Carousel */}
            {/* TODO: Client Review */}

            <div>
                <SectionHeader
                    title={"WHAT EVERYONE SHOULD KNOW ABOUT FLUID MANAGEMENT"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Renovation}
                                title="Renovation and retrofitting"
                                desc={"We renovate old machines and systems, bringing them up to the current state of the technology with respect to efficiency and safety."} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={Installation}
                                title="Installation of pipe and hose line systems"
                                desc={"Installing a full system of pipe and hose lines on machines and plants is complex and time-consuming. We can perform that task for you."} /></Col>
                            <Col sm={12} md={6} lg={4}><ImageCard image={TrainingProgramme}
                                title="Our training programme"
                                desc={"Keep your specialist knowledge up to date and extend it with our comprehensive range of training courses and seminars in fluid..."} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>
        </div>
    )
}

export default PlantProjectPlanningAndManagement