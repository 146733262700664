import React from "react";

function CustomPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        left: "0px",
        zIndex: 1,
        transform: "translateY(-50%)",
        cursor: "pointer",
        color: "red"

      }}
      onClick={onClick}
    >
      {/* Your custom arrow icon for previous */}
    </div>
  );
}

export default CustomPrevArrow;