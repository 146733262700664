import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';
import { Col, Container, Row } from 'react-bootstrap';

import imgStage from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Stage.jpg'
import DigitalMeasuring from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/DigitalMeasuring.jpg'
import AnalogueMeasuring from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/AnalogueMeasuring.jpg'
import MeasuringConnection from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/MeasuringConnection.jpg'
import ProvenMeasuring from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/ProvenMeasuring.jpg'
import Preasure from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Preasure.jpg'
import Volume from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Volume.jpg'
import Temperature from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Temperature.jpg'
import ReadyToInstall from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/ReadyToInstall.jpg'
import Gauges from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Gauges.jpg'
import Troubleshooting from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Troubleshooting.jpg'
import Stock from '../../../assets/Products/DriveAndControlTechnology/MeasuringTech/Stock.jpg'


const MeasuringTechnology = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const MeasuringTech1 = {
        image: DigitalMeasuring,
        title: 'Digital measuring technology',
        description: 'Digital measuring technology comes into its own when very exact measurements are needed or measurement data are to be recorded over longer time periods. This technology makes use of sensors to clearly evaluate any situation.',
        listItems: ["Digital 2- and 5-channel manual measuring device", "Digital pressure, temperature and rotational speed sensors", "Associated accessories such as measuring cables"],
    }

    const MeasuringTech2 = {
        image: AnalogueMeasuring,
        title: 'Analogue measuring technology',
        description: 'Not all measurements have to be accurate to three decimal places. In these cases, analogue measuring technology is a good choice. These devices are cost-effective and supply reliable measurement data.',
        listItems: ["Gauges with front ring and clamping ring mountings", "Flow monitors, volume flow meters and flow meters", "Accessories such as measuring hoses, fittings and shut-off valves"],
    }

    const MeasuringTech3 = {
        image: MeasuringConnection,
        title: 'Measuring connections, couplings and fittings',
        description: 'Measuring connections and fittings allow measuring points to be installed in the hydraulic system. Couplings allow quick and easy connection of components during diagnosis and servicing.',
        listItems: ["Measuring connections M16x2, M16x1.5 and S12.65x1.5", "Flat-seal couplings for diagnosis and servicing applications", "Clamping ring fittings in steel and stainless steel"],
    }

    const MeasuringTech4 = {
        image: ReadyToInstall,
        listItems: ["Analogue measuring device sets", "Digital measuring device sets", "For use during servicing and for long-term measurements"],
    }

    // const MeasuringTech5 = {
    //     image: ProvenMeasuring,
    //     listItems: ["Illustration of complex hydraulic switching arrangements", "Ready-to-install stacked valve arrangements to suit your requirements", "Supply of power units with stacked valve arrangements"],
    // }

    // features card carousel data
    const featuresData = [
        {
            title: "Comprehensive product range",
            description: "Measuring devices and analysis systems for pressure, flow and temperature measurement",
            image: imgMark
        },
        {
            title: "Best quality",
            description: "Extensively tested products with international approvals for the highest precision",
            image: imgMark
        },
        {
            title: "Customer-specific solutions",
            description: "Supplier of analogue and digital measuring systems to suit customer-specific requirements",
            image: imgMark
        },
        {
            title: "Support from experts",
            description: "Selection and installation of the measuring technology and condition monitoring of hydraulic systems",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Pressure measuring devices for all areas of use",
            description: "Pressure monitoring in hydraulic systems is essential for machines and equipment to operate smoothly and reliably. As well as simple gauges for measuring pressure, HANSA‑FLEX also supplies pressure sensors for highly precise measuring equipment for incorporation into electrical controls.",
            listItems: ["Allowable working pressure: Up to 600 bar", "Measuring accuracy: +/- 0.5 % of full scale", "Measuring ranges of our pressure sensors: 0 to 10 bar and 0 up to 600 bar"],
            image: Preasure
        },
        {
            title: "Volume flow meters from HANSA‑FLEX",
            description: "Volume flow meters measure the volume of oil flowing per unit of time. HANSA‑FLEX supplies volume flow meters, flow monitors, turbine flow and gear flow meters.",
            listItems: ["Measured volume: Up to 600 l per minute", "Measuring accuracy: +/- 0.5% of reading", "Measuring ranges: 1 to 20 l/min and 40 up to 600 l/min"],
            image: Volume
        },
        {
            title: "Reliable temperature measuring devices",
            description: "Simple temperature measuring devices such as thermometers or pressure-resistant sensors measure the temperature of the oil in hydraulic systems, in the tank or at separate measuring points. They also allow the user to monitor fluid quality, because temperatures of over 60 °C lead to premature ageing of hydraulic oil and have detrimental effects on viscosity.",
            listItems: ["Temperature measurement with electronic sensors", "Permissible highest temperature: 200 °C", "Measuring accuracy: +/- 2 Kelvin"],
            image: Temperature
        },
    ];

    const tabTitles = [
        { title: "Pressure", eventKey: "Pressure" },
        { title: "Volume", eventKey: "Volume" },
        { title: "Temperature", eventKey: "Temperature" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"MEASURING TECHNOLOGY FOR HYDRAULIC SYSTEMS FROM HANSA‑FLEX"}
                    paragraph={"Measuring technology in hydraulic systems must be highly precise. Pressure, temperature and volumetric flow need to be measured accurately to ensure systems and machines operate reliably. Continuous condition monitoring can be an effective way to prevent wear on hydraulic components. Digital measuring instruments are becoming increasingly popular for the control and regulation of automatic processes."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"ANALOGUE AND DIGITAL MEASURING TECHNOLOGY"}
                    subtitle={"Our online shop offers proven measuring technology and innovative measuring systems from renowned manufacturers for various fields of application. Our experts advise you on the choice and design of measuring devices, sensors and evaluation software."}
                />
                <ImageText
                    data={MeasuringTech1} />
                <ImageText
                    data={MeasuringTech2}
                    left={false} />
                <ImageText
                    data={MeasuringTech3} />
            </div>

            <div>
                <SectionHeader
                    title={"PURCHASE MEASURING TECHNOLOGY FROM THE FLUID SPECIALISTS"}
                    subtitle={"Precise measuring technology supplied by HANSA‑FLEX forms the basis for monitoring, controlling and optimising the operation of hydraulic systems."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"MEASURING TECHNOLOGY FOR RECORDING PRESSURE, VOLUMETRIC FLOW AND TEMPERATURE"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"Ready-to-install measuring technology sets in a practical transport case"}
                    subtitle={"Our online shop offers you complete measuring and analysis systems in addition to ready-to-use sets including sensors, connections, hoses and measuring cables in a robust transport case. We are also happy to configure customised measuring technology sets for your specific requirements."}
                />
                <ImageText
                    data={MeasuringTech4} />
            </div>

            {/* TODO: Carousal */}

            <div>
                <SectionHeader
                    title={"THESE PRODUCTS AND SERVICES MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={Gauges}
                                title="Gauges and thermometers for pneumatic systems"
                                desc={"We offer you various analogue and digital measuring systems for reliable pressure and temperature measurement for any number of pneumatic applications."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Troubleshooting}
                                title="Troubleshooting and plant optimisation"
                                desc={"Our Industrial Service experts support you in finding, analysing and rectifying faults or weak points in your systems so that your plant and equipment run optimally."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Stock}
                                title="Over 4,500 different hydraulic components in stock"
                                desc={"HANSA‑FLEX hydraulic components meet the highest quality standards. The wide range of products stocked ensures you receive them in the shortest possible time."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default MeasuringTechnology