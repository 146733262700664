import React, { useEffect } from 'react';
import ImageText from '../../Components/ImageText/ImageText';
import CenterStage from '../../Components/CenterStage/CenterStage';
import img1962 from '../../assets/History/1962.jpg'
import img1965 from '../../assets/History/1965.jpg'
import img1969 from '../../assets/History/1969.jpg'
import imghistory from '../../assets/History/imgHistory.jpg'
import img1972 from '../../assets/History 1970s/img1972.jpg'
import img1974 from '../../assets/History 1970s/img1974.jpg'
import img1975 from '../../assets/History 1970s/img1975.jpg'
import img1977 from '../../assets/History 1970s/img1977.jpg'
import ShowcaseCarousel from '../../Components/ShowcaseCarousel/ShowcaseCarousel';
import img2022 from '../../assets/History 2020s/img2022.jpg'
import img2010 from '../../assets/History 2020s/img2010.jpg'
import img2012 from '../../assets/History 2020s/img2012.jpg'

function GroupHistory() {

  const isMobile = window.innerWidth <= 768;


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const historyimg1962 = {
    image: img1962,
    title: 'The early years 1962-1989',
    description: 'In the early 1960s, Germany faced challenges in finding hydraulic components. Joachim Armerding recognized the opportunity and began assembling hydraulic hose lines in his garage. Personally delivering them to customers, he laid the groundwork for HANSA‑FLEX. By 1988, with Günter Buschmanns partnership and the founders sons involvement, the company expanded to 21 branches nationwide.'
  }

  const historyimg1965 = {
    image: img1965,
    title: 'Strong growth and internationalisation 1989-2009 ',
    description: 'During the era of German reunification and the fall of the Iron Curtain, HANSA‑FLEX experienced rapid growth. It established its first foreign branch in Elst, the Netherlands, in 1989, followed by expansions across Europe. Under new management in 1995, it expanded globally, with branches in Turkey, the Americas, Africa, and Asia by 2009 '
  }

  const historyimg1969 = {
    image: img1969,
    title: 'In the age of digitalisation – from 2009 to today',
    description: 'Amid global digitalization, HANSA‑FLEX enhances its investment in digital infrastructure and services. The HANSA‑FLEX app, online shop, and My.HANSA‑FLEX customer portal deliver substantial value. New international branches emerge in countries like Belgium, the UK, and India. In 2019, the PHOENIX digitalization initiative launches, and by 2020, HANSA‑FLEX AG serves over 150,000 customers worldwide.'
  }

  const historyimg2010 = {
    image: img2010,
    title: '2010',
    description: ' GmbH becomes HANSA- FLEX AG. The shares remain in the hands of the founding families. Thomas Armerding becomes chairman of the board of directors, with Uwe Buschmann serving as his deputy.'
  }

  const historyimg2012 = {
    image: img2012,
    title: '2012',
    description: 'HANSA‑FLEX  opens its own trainee branch near Kassel. The entire branch is managed by HANSA‑FLEX  AG trainees. '
  }

  const historyimg2022 = {
    image: img2022,
    title: '2022',
    description: 'The Deputy Chairman of the Board of Directors, Uwe Buschmann, goes into his well-deserved retirement after 36 years in the business. Florian Wiedemeyer is appointed to the board of directors as CFO.'
  }

  const carouselItems = [
    {
      imgSrc: img1972,
      title: '1972',
      description: 'Renovation of the first headquarters is inaugurated in Bremen-Mahndorf. In the same year, the third branch opens its doors in Elze, Lower Saxony.'
    },
    {
      imgSrc: img1974,
      title: '1974',
      description: 'With the establishment of the fourth branch in Duisburg, the name Joachim Armerding Industriebedarf is no longer appropriate. The company is renamed '

    },
    {
      imgSrc: img1975,
      title: '1975',
      description: 'The first uniform company logo is introduced and used until 1989, featuring a hose together with a standard fitting. Even then, '
    },

    {
      imgSrc: img1977,
      title: '1977',
      description: ' achieves an annual turnover of over ten million Deutschmarks for the first time.'

    }
  ];




  return (
    <div>
      <div>
        <CenterStage
          imageSrc={imghistory}
          title={"A FOCUS ON CUSTOMERS AND FLEXIBILITY SINCE 1962"}
          paragraph={"The history of HANSA‑FLEX began six decades ago in a small garage in Achim, near Bremen. In 1962, businessman Joachim Armerding starts manufacturing and selling hydraulic hose lines, laying the foundation for today’s HANSA‑FLEX AG. The company sees plenty of growth in Germany in the following years before going international in 1989. Today, HANSA‑FLEX is a global family business with foreign subsidiaries on all five continents."}
        />
      </div>

      <div className='div-gray'>


        <div >
          <br />
          <ImageText data={historyimg1962} left={false} /> <br /><br />
        </div>

        <div >
          <br />
          <ImageText data={historyimg1965} /> <br /><br />
        </div>

        <div >
          <br />
          <ImageText data={historyimg1969} left={false} /> <br /><br />
        </div>



      </div>

      <div>
        <ShowcaseCarousel carouselItems={carouselItems} isExploreButton={false} descDisplay='block' carouselType={2} />
      </div>

      <div className='div-gray' >
        <div >
          <br />
          <ImageText data={historyimg2010} /> <br /><br />
        </div>
        <div >
          <br />
          <ImageText data={historyimg2012} left={false} /> <br /><br />
        </div>
        <div >
          <br />
          <ImageText data={historyimg2022} /> <br /><br />
        </div>
      </div>




    </div>
  );
}

export default GroupHistory;