import React from 'react';
import { Accordion } from 'react-bootstrap';
import SectionHeader from '../SectionHeader/SectionHeader';
import './TextAccordion.css'; // Import the CSS file
import { BsCheckLg } from "react-icons/bs";

const TextAccordion = ({ title, subtitle, items }) => {
    return (
        <div className='text-accordion-body'>
            <SectionHeader title={title} subtitle={subtitle} />
            <div className="text-accordion-wrapper">
                <Accordion defaultActiveKey="0">
                    {items.map((item, index) => (
                        <Accordion.Item eventKey={String(index)} key={index} className="accordion-item">
                            <Accordion.Header>{item.header}</Accordion.Header>
                            <Accordion.Body>
                                {/* Description */}
                                <div>{item.description}</div>

                                {/* Points below the description */}
                                {item.points && (
                                    <ul className="accordion-points">
                                        {item.points.map((point, i) => (
                                            <li key={i}>
                                                <BsCheckLg className="li-promoter" />
                                                <span> {point.text}</span>
                                                
                                                {/* Map through the array of PDFs for each point */}
                                                <span className="pdf-downloads">
                                                    {point.pdfs.map((pdf, pdfIndex) => (
                                                        <React.Fragment key={pdfIndex}>
                                                            <a href={pdf.link} target='_blank'>{pdf.language} PDF</a>
                                                            {pdfIndex < point.pdfs.length - 1 && " | "} {/* Add separator only between links */}
                                                        </React.Fragment>
                                                    ))}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
            <br /><br /><br />
        </div>
    );
};

export default TextAccordion;
